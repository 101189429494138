export const API_ENDPOINT_TABLE_VIEW_LIST = `${process.env.REACT_APP_API_URL}/misc/table_view/list`;
export const API_ENDPOINT_TABLE_VIEW_ADD = `${process.env.REACT_APP_API_URL}/misc/table_view/add`;
export const API_ENDPOINT_OPEN_AI_SET_CONTEXT = `${process.env.REACT_APP_API_URL}/openai/context/default`;
export const API_ENDPOINT_OPEN_AI_CONVERSATION = `${process.env.REACT_APP_API_URL}/openai/conversation/init`;

export const STATE_NAME_CODE: any = {
  "01": "Jammu And Kashmir",
  "02": "Himachal Pradesh",
  "03": "Punjab",
  "04": "Chandigarh",
  "05": "Uttarakhand",
  "06": "Haryana",
  "07": "Delhi",
  "08": "Rajasthan",
  "09": "Uttar Pradesh",
  "10": "Bihar",
  "11": "Sikkim",
  "12": "Arunachal Pradesh",
  "13": "Nagaland",
  "14": "Manipur",
  "15": "Mizoram",
  "16": "Tripura",
  "17": "Meghlaya",
  "18": "Assam",
  "19": "West Bengal",
  "20": "Jharkhand",
  "21": "Odisha",
  "22": "Chattisgarh",
  "23": "Madhya Pradesh",
  "24": "Gujarat",
  "26": "Dadra And Nagar Haveli And Daman And Diu",
  "27": "Maharashtra",
  "28": "Andhra Pradesh",
  "29": "Karnataka",
  "30": "Goa",
  "31": "Lakshwadeep",
  "32": "Kerala",
  "33": "Tamil Nadu",
  "34": "Puducherry",
  "35": "Andaman And Nicobar Islands",
  "36": "Telangana",
  "37": "Andhra Pradesh",
  "38": "Ladakh",
};

export const dashboardTabConfig: any = {
  mmt: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
    {
      key: "2",
      label: "Dashboard With Error - Airline",
    },
    { key: "3", label: "Dashboard - Hotel" },
    { key: "4", label: "Dashboard - Hotel New" },
  ],
  cleartrip: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
    {
      key: "2",
      label: "Dashboard With Error - Airline",
    },
  ],
  balmer: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
  pyt: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
  myyatra: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
  samsung: [
    {
      key: "1",
      label: "Airline",
    },
    {
      key: "2",
      label: "Hotel",
    },
  ],
};

export const dashboardURLConfig: any = {
  mmt: {
    1: "https://analytics.zoho.in/open-view/103074000029023867/f37053b66a4e652e63285be2992b8bb9",
    2: "https://analytics.zoho.in/open-view/103074000028957085/4958d45a5c947c04a33134eb9b08665d",
    3: "https://analytics.zoho.in/open-view/103074000030162275/a774b1d8f8e20e02d236feac7488ca89",
    4: "https://analytics.zoho.in/open-view/103074000030587436/e6b519445b0bb98b29c86ec6cada288b",
  },
  cleartrip: {
    1: "https://analytics.zoho.in/open-view/103074000029079389/e61c0cc77b762e70705fbabbe2b24c65",
    2: "https://analytics.zoho.in/open-view/103074000028990582/1417ab2b1c1b7ec53f62d2b6723c70e3",
  },
  balmer: {
    1: "https://analytics.zoho.in/open-view/103074000030471453/86b3463a2430eca07d0f142aab816278",
  },
  pyt: {
    1: "https://analytics.zoho.in/open-view/103074000030470345/2befc1662b14e9ec9d5e84e481be57fa",
  },
  myyatra: {
    1: "https://analytics.zoho.in/open-view/103074000031555136/6aadc73c1e692c363cfa9c768a53ec19",
  },
  samsung: {
    1: "https://analytics.zoho.in/open-view/103074000032140242/b3d26a506b2e96978ca0b9658c6be966",
    2: "https://analytics.zoho.in/open-view/103074000032163764/4572c8d5aeed56671664040cc2c75185",
  },
};
export const appMetaConfig: any = {
  mmt: {
    title: "MakeMyTrip - Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/mmt_short.png",
    logo: "https://static-assets.finkraftai.com/logo/mmt.png",
    flight_recon: "AIRLINE_RECON_MMT",
    hotel_recon: "HOTEL_RECON_MMT",
    flight_recon_table: "airline_recon_mmt",
    hotel_recon_table: "hotel_recon_mmt",
  },
  cleartrip: {
    title: "ClearTrip - Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/cleartrip_short.png",
    logo: "https://static-assets.finkraftai.com/logo/cleartrip.png",
    flight_recon: "AIRLINE_RECON",
    flight_recon_table: "flight_recon_main",
  },
  app: {
    title: "App Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/finkraft_short.svg",
    logo: "https://static-assets.finkraftai.com/logo/finkraft.png",
  },
  hotelsgst: {
    title: "App Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/finkraft_short.svg",
    logo: "https://static-assets.finkraftai.com/logo/finkraft.png",
  },
  pyt: {
    title: "PickYourTrail -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/pyt.png",
    logo: "https://static-assets.finkraftai.com/logo/pyt.png",
    flight_recon: "AIRLINE_RECON_PYT",
    flight_recon_table: "airline_recon_py",
  },
  balmer: {
    title: "Balmer -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/balmer.png",
    logo: "https://static-assets.finkraftai.com/logo/balmer.png",
    flight_recon: "AIRLINE_RECON_BALMER",
    flight_recon_table: "airline_recon_balmer",
  },
  myyatra: {
    title: "Yatra -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/yatra_short.png",
    logo: "https://static-assets.finkraftai.com/logo/yatra.png",
    flight_recon: "AIRLINE_RECON_BALMER",
    flight_recon_table: "airline_recon_balmer",
  },
  samsung: {
    title: "Samsung -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/samsung.png",
    logo: "https://static-assets.finkraftai.com/logo/samsung.png",
    flight_recon: "AIRLINE_RECON_BALMER",
    flight_recon_table: "airline_recon_balmer",
  },
};
