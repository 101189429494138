// GstinModel.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
import './App.css'; // Import the CSS file
import { AgTableClient } from 'app/shared/AgTable';

const GstinModel = ({ isOpen, onClose, workspaceId, workspaceName }) => {
  const [rowData, setRowData] = useState([]);
  const [localStatuses, setLocalStatuses] = useState({});

  useEffect(() => {
    if (isOpen && workspaceId) {
      axios.get(`https://stage-apiplatform.finkraft.ai/api/meta/gstin/workspace/${workspaceId}`)
        .then(response => {
          const data = response.data.data;
          setRowData(data);

          // Initialize localStatuses
          const statuses = {};
          data.forEach(row => {
            statuses[row.gstin] = row.is_active || false; // Assuming 'is_active' is the status field
          });
          setLocalStatuses(statuses);
        })
        .catch(error => {
          console.error('Error fetching GSTINs:', error);
        });
    }
  }, [isOpen, workspaceId]);

  const handleCheckboxChange = (gstin) => {
    setLocalStatuses(prev => {
      const newStatuses = { ...prev, [gstin]: !prev[gstin] };
      // Update rowData with new statuses
      setRowData(prevData => prevData.map(row => 
        row.gstin === gstin ? { ...row, is_active: newStatuses[gstin] } : row
      ));
      return newStatuses;
    });
  };

  const updateGstinStatus = (gstin) => {
    const status = localStatuses[gstin];
    axios.post('https://stage-apiplatform.finkraft.ai/api/meta/update_gstin_status', {
      gstin,
      status
    })
      .then(response => {
        alert(`Status updated successfully to: ${status}`);
      })
      .catch(error => {
        console.error('There was an error updating the status!', error);
      });
  };

  const columnDefs = [
    { headerName: 'GSTIN', field: 'gstin', sortable: true, filter: true },
    { headerName: 'Entity Name', field: 'entity_name', sortable: true, filter: true },
    { headerName: 'PAN', field: 'pan', sortable: true, filter: true },
    {
      headerName: 'Is Active',
      field: 'is_active',
      cellRenderer: params => (
        <input
          type="checkbox"
          checked={localStatuses[params.data.gstin] || false}
          onChange={() => handleCheckboxChange(params.data.gstin)}
        />
      ),
    },
    { headerName: "Created At", field: "created_at" },
    {
      headerName: 'Action',
      cellRenderer: params => (
        <button
          onClick={() => updateGstinStatus(params.data.gstin)}
          className="gstin-save-button"
        >
          Save
        </button>
      )
    }
  ];

  return (
    isOpen && (
      <div className="gstin-modal">
        <div>
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h3>GSTINs for Workspace : {workspaceName}</h3>
          <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
            {rowData.length > 0 ? (
              <AgTableClient
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={{ flex: 1}}
              />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <span>No GSTINs available</span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
  
};

export default GstinModel;
