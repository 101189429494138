import AppIcon from "static/images/FinLogo.png";
import "./PrimaryNav.scss";
import {
  SettingOutlined,
  ReconciliationOutlined,
  LockFilled,
  ScanOutlined,
  MailOutlined,
  PieChartOutlined,
  LockOutlined,
} from "@ant-design/icons";
import MMTLogo from "static/images/logos/mmt_logo.svg";
import ClientIcon from "static/images/mmtLogo.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HotelIcon from "@mui/icons-material/Hotel";
// {
//   "title": "Flight",
//   "icon": "FlightTakeoffRoundedIcon",
//   "subMenus": [
//     {
//       "title": "Booking vs Invoice",
//       "route": "/flights/bookingvsinvoice"
//     },
//     {
//       "title": "2B vs IRN",
//       "route": "/flights/invoicevs2b"
//     }
//   ],
//   "route": "/flights"
// },
// {
//   "title": "Credentials",
//   "icon": "KeyRoundedIcon",
//   "subMenus": [
//     {
//       "title": "GST Credentials",
//       "icon": "PercentRoundedIcon",
//       "subMenus": [],
//       "route": "/credentials/gst"
//     },
//     {
//       "title": "Airline Credentials",
//       "icon": "FlightTakeoffRoundedIcon",
//       "subMenus": [],
//       "route": "/credentials/airline"
//     },
//     {
//       "title": "Email Credentials",
//       "icon": "MailOutlined",
//       "subMenus": [],
//       "route": "/credentials/mail"
//     }
//   ],
//   "route": "/credentials"
// },

import {
  Avatar,
  Divider,
  Drawer,
  Dropdown,
  Input,
  MenuProps,
  Modal,
} from "antd";
import Typography from "../Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuList from "./MenuItems.json";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useRecoilState } from "recoil";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { userInfo } from "app/config/States/users";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import RocketLaneIcon from "static/icons/rocketlane.png";
import Lottie from "react-lottie";
import { colorPicker } from "app/utils/color.helper";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_GENERATE_MAGIC_LINK_ROCKETLANE } from "app/scenes/Onboarding/onboarding.constants";
import TourIcon from "@mui/icons-material/Tour";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import GroupIcon from "@mui/icons-material/Group";
import LuggageIcon from "@mui/icons-material/Luggage";
import { appMetaConfig } from "global.constants";
import { getSubdomain } from "app/utils/generic.helper";
import Login from "app/scenes/Auth/components/Login";
import LoginOTP from "app/scenes/Auth/components/LoginOTP";
const menuIconMap: any = {
  TimelineRoundedIcon: <TimelineRoundedIcon style={{ fontSize: 28 }} />,
  KeyRoundedIcon: <KeyRoundedIcon style={{ fontSize: 28 }} />,
  QueryStatsRoundedIcon: <QueryStatsRoundedIcon style={{ fontSize: 28 }} />,
  NotificationsRoundedIcon: (
    <NotificationsRoundedIcon style={{ fontSize: 28 }} />
  ),
  ReconciliationOutlined: <ReconciliationOutlined style={{ fontSize: 28 }} />,
  FlightTakeoffRoundedIcon: (
    <FlightTakeoffRoundedIcon style={{ fontSize: 24 }} />
  ),
  PercentRoundedIcon: <PercentRoundedIcon style={{ fontSize: 24 }} />,
  MailOutlined: <MailOutlined style={{ fontSize: 24 }} />,
  DescriptionOutlinedIcon: <DescriptionOutlinedIcon style={{ fontSize: 24 }} />,
  SignalCellularAltOutlinedIcon: (
    <SignalCellularAltOutlinedIcon style={{ fontSize: 24 }} />
  ),
  TourIcon: <TourIcon style={{ fontSize: 24 }} />,
  LuggageIcon: <LuggageIcon style={{ fontSize: 22 }} />,
  WorkspacesIcon: <WorkspacesIcon style={{ fontSize: 22 }} />,
  GroupIcon: <GroupIcon style={{ fontSize: 22 }} />,
  AddToPhotosIcon: <AddToPhotosIcon style={{ fontSize: 22 }} />,
  HotelRoundedIcon: <HotelRoundedIcon style={{ fontSize: 22 }} />,
  HomeOutlinedIcon: <HomeOutlinedIcon style={{ fontSize: 22 }} />,
  CloudUploadOutlinedIcon: <CloudUploadOutlinedIcon style={{ fontSize: 22 }} />,
  HotelIcon: <HotelIcon style={{ fontSize: 22 }} />,
};

const settingSubMenu = [
  {
    title: "Members",
    icon: "GroupIcon",
    subMenus: [],
    route: "/settings/members",
  },
  {
    title: "Workspaces",
    icon: "WorkspacesIcon",
    subMenus: [],
    route: "/settings/workspace",
  },
];

export default function OpenPrimaryNav(props: any) {
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [loginStage, setLoginStage] = useState("EMAIL");
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const domain = getSubdomain();
  const navigate = useNavigate();
  const [openNestedMenu, setOpenNestedMenu] = useState(false);
  const [loginInfo, setLoginInfo] = useState<any>(null);
  const [targetLocation, setTargetLocation] = useState("/dashboard");
  // const domain = "mmt";

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  const handleLoginWithOTP = (loginInfo: any) => {
    setLoginInfo(loginInfo);
    setLoginStage("OTP");
  };

  const bottomMenuItems: MenuProps = {
    items: [
      {
        key: "1",
        label: (
          <>
            <div className="UserAvatarInfoPrimaryNav">
              <Avatar style={{ backgroundColor: colorPicker("primary.700") }}>
                {userDetails?.email?.[0]?.toUpperCase()}
              </Avatar>
              <div className="UserInfo">
                <Typography variant="body2" weight={600}>
                  {userDetails?.name}
                </Typography>
                <Typography variant="xs" weight={400} style={{ color: "gray" }}>
                  {userDetails?.email}
                </Typography>
              </div>
            </div>
            <Divider style={{ margin: 0, marginTop: 8 }} />
          </>
        ),
        disabled: true,
      },
      {
        key: "2",
        label: <Link to={"/gamification/profile/"}>Profile</Link>,
      },
      {
        key: "4",
        danger: true,
        label: <div onClick={handleLogout}>Logout</div>,
      },
    ],
  };

  const appFinkraftMenu: any = [
    {
      title: "Invoices",
      icon: "AddToPhotosIcon",
      subMenus: [],
      route: "/invoices",
    },

    {
      title: "Reports",
      icon: "DescriptionOutlinedIcon",
      subMenus: [],
      route: "/reports",
    },
  ];

  return (
    <div className="PrimaryNav">
      <div className="NavigationController">
        <div className="TopNavigation">
          <div className="AppLogo">
            <img
              alt="appIcon"
              src={appMetaConfig[getSubdomain() || "app"]?.icon}
            />
          </div>
          <div className="MenuContainer">
            {[...MenuList].map((menuItem: any, key: any) => {
              return (
                <div
                  key={key}
                  className={"MenuItem "}
                  onClick={() => {
                    setShowModal(true);
                    setTargetLocation(menuItem.route);
                  }}
                >
                  {menuIconMap[menuItem.icon]}
                  <Typography variant="xs">{menuItem.title}</Typography>
                </div>
              );
            })}
          </div>
        </div>

        <div className="BottomNavigation">
          {/* {userDetails.is_admin ? (
            <>
              <div className={"MenuItem "} onClick={() => {}}>
                <BusinessCenterOutlinedIcon
                  onClick={() => {
              
                  }}
                  style={{ color: colorPicker("neutral.700") }}
                />
                <Typography variant="xs">Workspaces</Typography>
              </div>
              <div className={"MenuItem "} onClick={() => {}}>
                <GroupAddOutlinedIcon
                  style={{ color: colorPicker("neutral.700") }}
                  onClick={() => {
                    // navigate("/settings/profile");
                    // handleOpenNestedMenu({
                    //   title: "Settings",
                    //   subMenus: settingSubMenu,
                    // });
                  }}
                />
                <Typography variant="xs">Members</Typography>
              </div>
            </>
          ) : null} */}

          <div className="MenuItem" style={{ zIndex: 99999, marginBottom: 0 }}>
            <Avatar
              size={36}
              style={{ backgroundColor: colorPicker("primary.700") }}
            >
              {userDetails?.email?.[0]?.toUpperCase()}
            </Avatar>
          </div>

          <div
            className="MenuItem"
            style={{ marginBottom: 0, marginTop: 28, cursor: "auto" }}
          >
            <Typography
              variant="xs"
              style={{
                color: colorPicker("neutral.600"),
                fontSize: 10,
                marginBottom: 8,
              }}
            >
              Powered By
            </Typography>
            <img
              src={AppIcon}
              alt="FinkLogo"
              style={{ width: 48, opacity: 0.8, marginBottom: 12 }}
            />
          </div>
        </div>
      </div>
      <Modal
        title={
          <div>
            <LockOutlined /> Authentication Required
          </div>
        }
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <div style={{ marginTop: 24 }}>
          {loginStage === "EMAIL" ? (
            <Login
              noRouting
              onOTPSend={handleLoginWithOTP}
              onSuccess={() => navigate(targetLocation || "/dashboard")}
            />
          ) : (
            <LoginOTP
              noRouting
              loginInfo={loginInfo}
              onOTPSend={handleLoginWithOTP}
              onSuccess={() => navigate(targetLocation)}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
