import {
  Button,
  FloatButton,
  Spin,
  Tag,
  Upload,
  UploadProps,
  message,
} from "antd";
import {
  LoadingOutlined,
  RightCircleOutlined,
  ExportOutlined,
  DownloadOutlined,
  CheckOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { colorPicker } from "app/utils/color.helper";
import onboaringIllustration from "static/images/illustration/onboaringIllustration.png";
import { AgTableClient } from "app/shared/AgTable";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import csv from "csvtojson";
import { apiPost } from "app/services/apiServices";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import moment from "moment";
import { API_ENDPOINT_ONBOARDING_CHECK_CREDS_LOGIN } from "../../onboarding.constants";
import {
  API_ENDPOINT_GST_ADD_GSTINS,
  API_ENDPOINT_GST_ADD_PAN,
  API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE,
} from "app/scenes/Credentials/credentials.constants";
import Typography from "app/shared/Typography";
import { API_ENDPOOINT_ONBOARDING_UPDATE_WORKSPACE_META } from "app/scenes/OnboardingV2/onboardingv2.constants";

export default function ValidateCredentials(props: any) {
  const gridRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [tableData, setTableData] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const currentWorkspace = localStorage.getItem("currentWorkspace");
  const [apiCalled, setApiCalled] = useState(false);
  useEffect(() => {
    if (props.tableData.data) {
      setTableData(props.tableData.data);

      // Make sure it's only called once
      if (props.tableData.type !== "PAN" && !props.tableData.called) {
        scrapperVerifyCredentials(props.tableData.data);
        // Optionally, you can mark it as called to prevent further execution
        props.tableData.called = true;
      }
    }
  }, [props.tableData]);

  const defaultDefs = [
    {
      headerName: "GSTIN",
      field: "gstin",
    },
    {
      headerName: "Legal Name",
      field: "bname",
    },
    {
      headerName: "Trade Name",
      field: "bname",
    },
    {
      headerName: "E-Invoice",
      field: "einvStatus",
    },
    {
      headerName: "Username",
      field: "username",
      editable: true,
    },
    {
      headerName: "Password",
      field: "password",
      editable: true,
    },
  ];

  let statusColumn = {
    headerName: "Status",
    field: "status",
    cellRenderer: (params: any) => {
      return params?.data?.status === "VALID" ? (
        <Tag color={"green"} icon={<CheckCircleFilled />}>
          Valid
        </Tag>
      ) : params?.data?.status === "INVALID" ? (
        <Tag color={"red"} icon={<CloseCircleFilled />}>
          Invalid
        </Tag>
      ) : (
        <Tag color="orange">
          <Spin
            style={{ marginRight: 6 }}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 14, color: "#d46b08" }}
                spin
              />
            }
          />
          Processing
        </Tag>
      );
    },
  };

  let editColumn = {
    field: "action",
    headerName: "Action",
    enableRowGroup: true,
    editRow: true,
    focusKey: "username",
    cellRenderer: AgTableEditButton,
    cellRendererParams: {
      onClick: (params: any) => console.log(params), // Define your edit logic here
    },
  };
  const location = useLocation();
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isSubmitted, setSubmitted] = useState(false);
  const [hasCSVError, setHasCSVError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [processedIndices, setProcessedIndices] = useState(new Set()); // To track processed items

  useEffect(() => {
    if (props.tableData.type === "GSTIN") {
      setColumnDefs([...defaultDefs, statusColumn]);
    } else {
      setColumnDefs([...defaultDefs, editColumn]);
    }
  }, [props.tableData]);

  const scrapperVerifyCredentials = async (dataList: any[]) => {
    const updatedData = [...dataList]; // Create a local copy to accumulate results
    //@ts-ignore
    for (const [index, item] of dataList.entries()) {
      if (!item.username) {
        updatedData[index] = { ...item, status: "MISSING" };
        continue; // Skip to the next item if no username
      }

      // Check if this index has already been processed
      if (processedIndices.has(index)) {
        continue; // Skip if already processed
      }

      try {
        const response = await apiPost(
          API_ENDPOINT_ONBOARDING_CHECK_CREDS_LOGIN,
          { username: item.username, password: item.password }
        );
        console.log(response);

        updatedData[index] = {
          ...updatedData[index],
          status: response.success ? "VALID" : "INVALID",
          gstin: response?.api_response?.gstin || item.gstin, // Only update if response has a value
          einvStatus:
            response?.api_response?.einvStatus || updatedData[index].einvStatus, // Update only if present
          bname: response?.api_response?.bname || updatedData[index].bname, // Update only if present
        };

        // Mark this index as processed
        processedIndices.add(index);
      } catch (error) {
        console.error("Error fetching data:", error);
        updatedData[index] = {
          ...updatedData[index],
          status: "INVALID",
          gstin: item.gstin, // Retain the original gstin
        };
        // Mark this index as processed
        processedIndices.add(index);
      }
    }

    // Update tableData once after processing all items
    setTableData(updatedData);
  };

  // const scrapperVerifyCredentials = async (tableDataList: any[]) => {
  //   for (let i = 0; i < tableDataList.length; i++) {
  //     if (tableDataList[i] && !tableDataList[i].username) {
  //       setTableData((prevData) => {
  //         const newData: any = [...prevData]; // Create a copy of the state array
  //         newData[i] = {
  //           ...newData[i],
  //           status: "MISSING",
  //         }; // Update the object at the specified index
  //         return newData; // Return the updated array to update the state
  //       });
  //       return;
  //     }
  //     // Example: Call API 5 times
  //     try {
  //       const response = await apiPost(
  //         API_ENDPOINT_ONBOARDING_CHECK_CREDS_LOGIN,
  //         tableDataList[i]
  //       );
  //       console.log(response); // Handle the response data
  //       setTableData((prevData) => {
  //         const newData: any = [...prevData]; // Create a copy of the state array
  //         newData[i] = {
  //           ...newData[i],
  //           status: response.success ? "VALID" : "INVALID",
  //           gstin: response?.api_response?.gstin || tableDataList[i]?.gstin,
  //           einvStatus: response?.api_response?.einvStatus,
  //           bname: response?.api_response?.bname,
  //         }; // Update the object at the specified index
  //         return newData; // Return the updated array to update the state
  //       });
  //     } catch (error) {
  //       // console.error("Error fetching data:", error);
  //       setTableData((prevData) => {
  //         const newData: any = [...prevData]; // Create a copy of the state array
  //         newData[i] = {
  //           ...newData[i],
  //           status: "INVALID",
  //           gstin: tableDataList[i]?.gstin,
  //         }; // Update the object at the specified index
  //         return newData; // Return the updated array to update the state
  //       });
  //     }
  //   }
  // };

  const handleLogOnboardTime = async () => {
    const response = await apiPost(
      API_ENDPOOINT_ONBOARDING_UPDATE_WORKSPACE_META,
      { requestedat: true }
    );

    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Request sent!",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not send request",
      });
    }
  };

  const handleAddCredentials = async (jsonData: any) => {
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE, {
      credentials: jsonData,
    });
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Credentials saved",
      });

      setLoading(false);
      handleLogOnboardTime();
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
    }
  };

  function getPanFromGstin(gstin: string) {
    // Validate the GSTIN format
    const gstinPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (!gstinPattern.test(gstin)) {
      throw new Error("Invalid GSTIN format");
    }

    // Extract the PAN number (characters 3 to 12)
    const panNumber = gstin.slice(2, 12);
    return panNumber;
  }

  const handleAddPanNumbers = async (jsonData: any) => {
    console.log("gstin list while adding pans", jsonData);

    for (let i = 0; i < jsonData.length; i++) {
      try {
        let panFromGSTIN = getPanFromGstin(jsonData[i].gstin);
        await apiPost(API_ENDPOINT_GST_ADD_PAN, {
          pan: panFromGSTIN,
          entity_name: jsonData[i].bname,
          workspace_id: currentWorkspace,
        });
      } catch (error) {
        console.log("could not add pan");
      }
    }
    handleAddGstins(jsonData);
  };

  const handleAddGstins = async (jsonData: any) => {
    let failedCred: any = [];
    console.log("gstin list while adding gstin", jsonData);
    for (let i = 0; i < jsonData.length; i++) {
      if (!jsonData[i]?.gstin) {
        failedCred.push(jsonData[i]?.username);
        continue;
      }
      await apiPost(API_ENDPOINT_GST_ADD_GSTINS, {
        gstin: jsonData[i]?.gstin,
        entity_name: jsonData[i]?.bname || "NA",
        pan: jsonData[i]?.gstin?.substring(2, 12),
      });
    }
    console.log("failed creds", failedCred);
    const filteredCredentials = jsonData.filter(
      (item: any) => !failedCred.includes(item.username)
    );
    handleAddCredentials(filteredCredentials);
  };

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToCSV) {
      // Call the child component's function
      const jsonData = gridRef.current.exportJSONData();
      console.log("json data", jsonData);
      uploadcredfilestoS3(jsonData);
    }
  };

  const handleSubmitCredentials = () => {
    if (hasCSVError) {
      messageApi.error({
        type: "error",
        content:
          "Invalid csv, mybe some filed does not have value( ie: username)",
      });
    } else {
      setSubmitted(true);
      let filteredItems = columnDefs.filter((item) => item.field !== "action");
      setColumnDefs([...filteredItems, statusColumn]);
      scrapperVerifyCredentials(tableData);
    }
  };

  const beforeUploadHandler = () => {
    return false; // Allow default action
  };

  const furtherProcessJSON = (jsonData: any) => {
    const keysToCheck = ["gstin", "username", "password"];

    const allObjectsContainKeysAndValues = jsonData.every((obj: any) =>
      keysToCheck.every((key) => obj.hasOwnProperty(key) && obj[key])
    );
    if (allObjectsContainKeysAndValues) {
      setHasCSVError(false);
      //   handleBulkUpload(jsonData);
      // setBulkData(jsonData);
      setTableData(jsonData);
    } else {
      setHasCSVError(true);
      messageApi.error({
        type: "error",
        content:
          "Invalid csv, mybe some filed does not have value( ie: username)",
      });
    }
  };

  const handleReadFileData = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const text = e.target.result;
        const jsonArray = await csv().fromString(text);
        console.log("jsonArray", jsonArray);
        furtherProcessJSON(jsonArray);
      };

      reader.readAsText(file);
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: beforeUploadHandler,

    onChange(info) {
      const { status } = info.file;
      console.log("new file", info.file);
      handleReadFileData(info.file);
    },
  };

  const uploadcredfilestoS3 = async (jsonData: any) => {
    setLoading(true);
    const response = await apiPost(
      `${process.env.REACT_APP_API_URL}/storage/objects/upload/json?filename=${
        userDetails.clusterId
      }_${moment().unix()}.csv`,
      {
        data: jsonData,
      }
    );
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Credentials saved",
      });
      // props.goNext && props.goNext();
      handleAddCredentials(jsonData);
    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="Greeting"
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "18px",
        height: "100%",
      }}
    >
      <div
        className="TableContainer"
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        {!isSubmitted && props.tableData.type === "PAN" ? (
          <div
            style={{
              padding: "10px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.tableData.type === "PAN" ? (
              <>
                <div style={{ display: "flex" }}>
                  <Upload {...uploadProps}>
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginRight: 24 }}
                      icon={<DownloadOutlined />}
                      shape="round"
                    >
                      Upload CSV
                    </Button>
                  </Upload>

                  <Button
                    size="small"
                    type="primary"
                    shape="round"
                    ghost
                    onClick={handleExportTableData}
                    icon={<ExportOutlined />}
                  >
                    Download CSV From Table
                  </Button>
                </div>
                <div>
                  <Button
                    shape="round"
                    size="small"
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={handleSubmitCredentials}
                  >
                    Submit Credentials
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        ) : null}

        {columnDefs.length > 0 ? (
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <AgTableClient
              //@ts-ignore
              rowData={tableData}
              hideToolbar
              columnDefs={columnDefs}
              ref={gridRef}
              autoResize
            />
            <div
              style={{
                marginTop: 16,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  onClick={props.goBack}
                  style={{ marginRight: 24 }}
                  icon={<ArrowLeftOutlined />}
                >
                  Go Back
                </Button>
                <Button
                  onClick={() =>
                    gridRef.current && gridRef.current.exportDataToExcel()
                  }
                  style={{ marginRight: 24 }}
                  type="primary"
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
              </div>

              <Button type="primary" onClick={handleExportTableData}>
                Submit Credentials
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      {/* {(props.tableData.type === "PAN" && isSubmitted) ||
      props.tableData.type === "GSTIN" ? (
        <FloatButton
          onClick={handleExportTableData}
          style={{ marginRight: 24, width: 200 }}
          shape="square"
          type="primary"
          icon={
            <div style={{ display: "flex", width: "100%" }}>
              <Typography style={{ color: "white", marginRight: 6 }}>
                {isLoading ? "Saving...." : "Notify Me Once Done"}
              </Typography>
              <RightCircleOutlined />
            </div>
          }
        />
      ) : null} */}
      {contextHolder}
    </div>
  );
}
