import React, { useEffect, useState } from "react";
import Modal from "react-modal";
// import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import "./App.css"; // Optional: Import your CSS file for custom styles
import axios from "axios";
import CreatePanModal from "./createPanModel";
import { filter } from "lodash";
import { AgTableClient } from "app/shared/AgTable";

Modal.setAppElement("#root");

const PanModal = ({
  isOpen,
  onRequestClose,
  workspaceId,
  workspaceName,
  existingName,
  existingIsActive,
  existingBoxFolderId,
  onUpdate
}) => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: existingName,
    is_active: existingIsActive,
    box_folder_id:existingBoxFolderId
  });
  const [isCreatePanModalOpen, setIsCreatePanModalOpen] = useState(false);
  const [newPan, setNewPan] = useState("");
  const [newEntityName, setNewEntityName] = useState("");
  const [panCount, setPanCount] = useState(0); // Track PAN count

  const columnDefs = [
    { headerName: "Pans", field: "pan", width: 300,filter: true, },
    { headerName: "Entity", field: "entity_name", width: 300, filter: true },
    {
      headerName: "Action",
      field: "action",
      filter: true,
      cellRenderer: (params) => (
        <button
          style={{
            backgroundColor: "red",
            color: "white",
            height: "40px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border:'none',
            padding:'0px 10px'
          }}
          onClick={() => handleRemoveWorkspace(params.data.pan)}
        >
          Delete
        </button>
      ),
      width: 150,
    },
  ];


  // Inside your PanModal component
const handleRemoveWorkspace = async (panNumber) => {
  try {
    const response = await axios.put(
      `https://stage-apiplatform.finkraft.ai/api/meta/pans/remove-workspace/${panNumber}`
    );

    if (response.status === 200) {
      // Successfully removed workspace ID
      setRowData((prevRowData) =>
        prevRowData.map((row) =>
          row.pan === panNumber ? { ...row, workspace_id: null } : row
        )
      );
      console.log(response.data.message);
      fetchPans();
    }
  } catch (error) {
    console.error('Error removing workspace ID:', error);
    alert('Failed to remove workspace ID.'); // Optional: Show error message to user
  }
};


  useEffect(() => {
    if (isOpen) {
      const fetchPans = async () => {
        try {
          const response = await axios.get(
            `https://apiplatform.finkraft.ai/meta/pans/${workspaceId}`
          );
          setRowData(response.data); // Adjust based on the structure of your API response
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch data.");
          setLoading(false);
        }
      };

      fetchPans();
      // Reset form data when modal opens
      setFormData({ name: existingName, is_active: existingIsActive,box_folder_id: existingBoxFolderId  });
    }
  }, [isOpen, workspaceId, existingName, existingIsActive,existingBoxFolderId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = () => {
    setFormData((prev) => ({ ...prev, is_active: !prev.is_active }));
  };

  const handleSubmit = async () => {
    const initialFormData = { name: existingName, is_active: existingIsActive, box_folder_id: existingBoxFolderId };
  
    let workspaceUpdated = false;
  
    if (
      formData.name !== initialFormData.name ||
      formData.is_active !== initialFormData.is_active ||
      formData.box_folder_id !== initialFormData.box_folder_id
    ) {
      workspaceUpdated = true;
  
      try {
        const workspaceResponse = await fetch(
          `https://stage-apiplatform.finkraft.ai/api/meta/workspaces/update/${workspaceId}`, // Send the workspaceId in the URL
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
  
        if (workspaceResponse.status === 200) {
          // Update the parent component's row data with the updated form data
          onUpdate({
            pan_count: panCount, // Include the updated PAN count
          });
          console.log("Workspace updated successfully");
          onRequestClose();
        } else {
          console.error("Failed to update workspace")
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    if (workspaceUpdated) {
      // Optionally, you can refresh data or show a success message here
      console.log("Workspace details updated");
    }
  };
  

  if (loading) {
    return <div>Loading...</div>; // You can customize loading state
  }

  if (error) {
    return <div>{error}</div>; // Handle error state
  }


  const handleClose = async () => {
    // Ensure latest data is fetched before closing
    const updatedPanCount = await fetchPans(); // Get the updated PAN count
    console.log("panCount on close:", updatedPanCount); 
  
    onUpdate({
      workspace_id:workspaceId,
      pan_count: updatedPanCount,
    });
  
    onRequestClose();
  };
  
  const fetchPans = async () => {
    try {
      const response = await axios.get(
        `https://apiplatform.finkraft.ai/meta/pans/${workspaceId}`
      );
      setRowData(response.data); // Adjust based on the structure of your API response
      const updatedPanCount = response.data.length; // Get the count from the response
      setPanCount(updatedPanCount); 
      console.log(updatedPanCount);
      
      setLoading(false);
      return updatedPanCount; // Return the updated count
    } catch (err) {
      setError("Failed to fetch data.");
      setLoading(false);
      return 0; // Return a default value in case of error
    }
  };
  


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="PAN Modal"
      className="custom-modal-pan"
      overlayClassName="custom-overlay"
    >
      <h2  style={{marginBottom:'20px' }}>PAN Details for Workspace : {workspaceName}</h2>

      {/* Input for Workspace Name */}
      <div className="form-box">
        <label htmlFor="workspace-name" className="workspacelabel">Workspace Name:</label>
        <input
          type="text"
          id="workspace-name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter workspace name"
          className="workspaceinput"
        />
      </div>
      {/* Input for Box Folder ID */}
      <div  className="form-box">
        <label className="workspacelabel">Box Folder ID:</label>
        <input
          type="text"
          name="box_folder_id"
          value={formData.box_folder_id}
          onChange={handleInputChange}
          placeholder="Enter Box Folder ID"
          className="workspaceinput"
        />
      </div>

      {/* Checkbox for Active Status */}
      <div  className="form-box">
        <label className="workspacelabel">
          <input
            type="checkbox"
            checked={formData.is_active}
            onChange={handleCheckboxChange}
          />
          Active
        </label>
      </div>
      <div>
        <button
          onClick={() => setIsCreatePanModalOpen(true)}
          style={{ marginBottom: "10px" }}
          className="modal-submit-button"
        >
          Add Pan
        </button>
        <CreatePanModal
          isOpen={isCreatePanModalOpen}
          onRequestClose={() => setIsCreatePanModalOpen(false)}
          workspaceId={workspaceId}
          workspaceName={existingName} // Auto-filled workspace name
          refreshPanList={fetchPans}
        />
      </div>

      {/* AG Grid for PAN Data */}
      <div className="ag-theme-alpine" style={{height:'300px', width: "100%" }}>
        <AgTableClient
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ flex: 1, minWidth: 100 }} // Ensure columns are responsive
        />
      </div>

      {/* Submit Button */}
      <button onClick={handleSubmit} className="modal-submit-button">
        Submit
      </button>
      <button onClick={handleClose} className="modal-close-button">
        Close
      </button>
    </Modal>
  );
};

export default PanModal;
