import PageHeader from "app/shared/PageHeader";
import "./ReportSelection.scss";
import { useEffect, useState } from "react";
import Loader from "app/shared/Loader";
import { AgTableClient } from "app/shared/AgTable";
import moment from "moment";
import { Button, message, Modal, Popconfirm, Select, Tag } from "antd";
import {
  EyeOutlined,
  GlobalOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import {
  API_ENDPOINT_REPORT_MANAGER_LIST,
  API_ENDPOINT_REPORT_PUBLISH_NOW,
} from "../../report.manager.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import OpenPrimaryNav from "app/shared/OpenPrimaryNav";
export default function OpenReportList(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [reportList, setReportList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  const { tokenId } = useParams();
  const [searchParams] = useSearchParams();
  const workspaceId = searchParams.get("workspaceId");

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      let findCurentWorkspace = (response.data.workspaces || []).find(
        (item: any) => item.id == workspaceId
      );
      setUserDetails({
        ...response.data,
        currentWorkspace: findCurentWorkspace
          ? findCurentWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
      });
      localStorage.setItem(
        "currentWorkspace",
        findCurentWorkspace
          ? findCurentWorkspace.id
          : response.data.workspaces[0]?.id
      );
      fetchReportList();
    }
  };

  useEffect(() => {
    if (tokenId) {
      localStorage.clear();

      localStorage.setItem("token", tokenId);
      fetchUserInfo();
    }
  }, []);

  const fetchReportList = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_REPORT_MANAGER_LIST, {
      workspace_id: workspaceId,
      published: true,
    });
    if (response.status) {
      setReportList(response.data);
    } else {
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: "report_name",
      headerName: "Report Name",
    },

    {
      field: "created_on",
      headerName: "Created On",
      cellRenderer: (params: any) => {
        return moment(params.data?.created_on).format("DD/MM/YYYY ");
      },
    },

    {
      field: "action",
      headerName: "Action",
      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="small"
              icon={<EyeOutlined />}
              onClick={() =>
                navigate("/reportmanager/view/" + params.data?._id, {
                  state: {
                    dbType: params.data?.meta?.dbType,
                    database: params.data?.meta?.database,
                    table: params.data?.meta?.table,
                    report_name: params.data?.report_name,
                    selectedWorkspaceId: params.data?.workspace_id,
                  },
                })
              }
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  const handleSelectWorkspace = (spaceInfo: any, fullObj: any) => {
    setSelectedWorkspace(spaceInfo);
  };

  const { Option } = Select;
  const copyDataToBoard = (data: any) => {
    navigator?.clipboard?.writeText(data);
    messageApi.open({
      type: "success",
      content: "copied",
    });
  };
  return (
    <div className="OpenReportList" style={{ height: "100vh" }}>
      <div className="PrimaryNavContainer">
        <OpenPrimaryNav />
      </div>
      <div className="MainSectionContainer">
        <div className="HeaderContainer">
          <PageHeader title="Report List" noAuth />
        </div>
        <div className="MainContainer">
          {isLoading ? (
            <Loader />
          ) : (
            <AgTableClient
              // @ts-ignore
              rowData={reportList}
              columnDefs={columnDefs}
              hideToolbar
              autoResize
            />
          )}
        </div>
      </div>

      {contextHolder}
    </div>
  );
}
