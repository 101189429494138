import { AgGridReact } from "ag-grid-react";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgTableClient, AgTableServer } from "app/shared/AgTable";
import { apiGet } from "app/services/apiServices";
import { handleCellRenderer } from "app/shared/AgTable/AgServerUtility";
import Loader from "app/shared/Loader";
import { Button } from "antd";
import { currentPDFData, userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_RECON_ZA_AIRLINE_DATA } from "app/scenes/AppTour/onboarding.constants";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import { tableSchema } from "app/config/States/agtable";
import { getProcessedDefinition, getSubdomain } from "app/utils/generic.helper";
import FinkAI from "app/shared/FinkAI";
import FlightReconDemo from "../FlightReconDemo";
const ChildComponent = forwardRef((props: any, ref) => {
  const [pdfViewerData, setPdfViewerData] = useState("");
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const openSidebarRenderer = (params: any) => {
    setPdfViewerData(params?.data);
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };
  useEffect(() => {
    getProcessedDefinitions();
  }, [tableSchemaInfo]);

  useImperativeHandle(ref, () => ({
    createNewView(viewName: string) {
      gridRef.current?.saveGridView(viewName, props.moduleId);
    },
    loadTableView(viewId: string) {
      gridRef.current?.loadGridView(viewId, props.moduleId);
    },
    updateTableView(viewId: string) {
      gridRef.current?.updateGridView(viewId, props.moduleId);
    },
    exportDataToExcel() {
      gridRef.current?.exportDataToExcel();
    },
    getAllColumns() {
      return gridRef.current?.getAllColumns();
    },
    getGridState() {
      return gridRef.current?.getServerSidePayload();
    },
  }));
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef: any = useRef();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [finkDataset, setFinkDataset] = useState<any[]>([]);
  const [recoilPDFData, setRecoilPdfData] =
    useRecoilState<string>(currentPDFData);

  const getProcessedDefinitions = () => {
    const columnObject = tableSchemaInfo.find(
      (item: any) => item.moduleId === props.moduleId
    );
    let columnArray = columnObject?.state?.columnDefs || [];
    let finalDefs = getProcessedDefinition(
      columnArray,
      "SERVER",
      setRecoilPdfData
    );
    // setColumnDefs(finalDefs);
    console.log("final Defs:", finalDefs);
    return finalDefs;
  };

  const domain = getSubdomain();

  const defaultFinkAction: any = [
    {
      name: "GST on Invoice Recevied Vs Not Received - Airline",
      model: {
        rowGroupCols: [
          { id: "Vendor2B", displayName: "Vendor", field: "Vendor2B" },
        ],
        filterModel: {},
        sortModel: [],
        pivotMode: true,
        pivotCols: [
          {
            id: "Main_Invoice_Status",
            displayName: "Invoice Status ",
            field: "Main_Invoice_Status",
          },
        ],
        valueCols: [
          {
            id: "TotalGST",
            aggFunc: "sum",
            displayName: "Total GST",
            field: "TotalGST",
          },
        ],
      },
    },
    {
      name: "Carrier wise Total Amount",
      model: {
        rowGroupCols: [
          { id: "Vendor2B", displayName: "Vendor", field: "Vendor2B" },
        ],
        filterModel: {},
        sortModel: [],
        pivotMode: true,
        pivotCols: [{ id: "Type2B", displayName: "Type", field: "Type2B" }],
        valueCols: [
          {
            id: "TotalAmount",
            aggFunc: "sum",
            displayName: "Total Amount",
            field: "TotalAmount",
          },
        ],
      },
    },
  ];

  return (
    <div
      className="UnReconContainer"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{ flex: 1, width: "100%", height: "100%" }}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader title="fetching your booking data from Makemytrip" />
          </div>
        ) : domain === "samsung" ? (
          <FlightReconDemo />
        ) : (
          <AgTableServer
            //@ts-ignore
            rowData={[]}
            columnDefs={getProcessedDefinitions()}
            endpoint={API_ENDPOINT_RECON_ZA_AIRLINE_DATA}
            // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
            pdfDataKey="Invoice_link"
            enablePDFViewer
            grandTotalRow
            pdfV2
            ref={gridRef}
            showStatusBar
            useWorkspace={
              userDetails?.currentWorkspace?.name === "MMT Admin"
                ? false
                : userDetails?.currentWorkspace?.meta?.full_access
                ? false
                : true
            }
            setData={(data: any) => setFinkDataset(data)}
          />
        )}
      </div>
      <FinkAI
        onResponse={(config: any) =>
          gridRef.current && gridRef.current.applyTestView(config)
        }
        defaultActions={defaultFinkAction}
        columnDefs={getProcessedDefinitions()}
        dataset={finkDataset}
      />
    </div>
  );
});

export default ChildComponent;
