import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './App.css'; // Assuming you save the CSS in a separate file

const CreatePanModal = ({ isOpen, onRequestClose, workspaceId, refreshPanList, workspaceName }) => {
  const [pans, setPans] = useState([]);
  const [selectedPans, setSelectedPans] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchPans = async () => {
      try {
        const response = await fetch('https://stage-apiplatform.finkraft.ai/api/meta/newpans');
        const data = await response.json();
        setPans(data);
      } catch (error) {
        console.error('Error fetching PANs:', error);
      }
    };

    if (isOpen) {
      fetchPans();
    }
  }, [isOpen]);

  const handleAddPan = async () => {
    try {
      const response = await fetch('https://stage-apiplatform.finkraft.ai/api/meta/pan/map', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pans: Array.from(selectedPans),
          workspace_id: workspaceId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add PANs');
      }

      const data = await response.json();
      console.log('PANs updated:', data);
      refreshPanList();
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  const togglePanSelection = (panId) => {
    setSelectedPans((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(panId)) {
        newSelected.delete(panId);
      } else {
        newSelected.add(panId);
      }
      return newSelected;
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the PANs based on the search term
  const filteredPans = pans.filter(
    (pan) =>
      (pan.pan && pan.pan.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (pan.entity_name && pan.entity_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="create-pan-modal">
      <h3>Map PAN for Workspace: {workspaceName}</h3>

      {/* Search Box */}
      <input
        type="text"
        placeholder="Search PANs or entity name"
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-box"
      />

      <h4>Select PANs</h4>
      <div className="pan-list">
        {filteredPans.map((pan) => (
          <div key={pan.pan}>
            <input
              type="checkbox"
              id={`checkbox-${pan.pan}`} // Unique ID for each checkbox
              checked={selectedPans.has(pan.pan)}
              onChange={() => togglePanSelection(pan.pan)}
            />
            <label htmlFor={`checkbox-${pan.pan}`}>{pan.pan} ({pan.entity_name})</label>
          </div>
        ))}
      </div>

      <div className="button-container">
        <button onClick={handleAddPan}>Add</button>
        <button onClick={onRequestClose} className="modal-close-button">Close</button>
      </div>
    </Modal>
  );
};

export default CreatePanModal;
