import React from "react";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { FilterOutlined } from "@ant-design/icons";

const CustomAgHeader = (props) => {
  const { displayName, enableSorting, enableMenu } = props;
  const [sort, setSort] = useState(null);

  const onSortRequested = (order, event) => {
    props.progressSort(order, event.shiftKey);
    setSort(order);
  };

  const columnID = props.column?.colDef.field;
  let summaryData = props.column?.colDef?.summaryData || {};
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {enableMenu && <div className="customHeaderMenuButton" />}
      {enableSorting && (
        <div
          className="customHeaderSortButton"
          onClick={(event) =>
            onSortRequested(sort === "asc" ? "desc" : "asc", event)
          }
        />
      )}
      <Typography
        variant="xs"
        style={{ marginRight: 12, color: "#374762" }}
        weight={500}
      >
        {displayName}
      </Typography>
      {summaryData[columnID] ? (
        <div
          style={{
            width: 40,
            height: 16,
            backgroundColor: colorPicker("red.100"),
            border: `1px solid ${colorPicker("red.700")}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: 4,
          }}
          onClick={() => props.applyFilter()}
        >
          <FilterOutlined
            style={{ color: colorPicker("red.700"), marginRight: 4 }}
          />
          <Typography
            variant="xs"
            style={{ color: colorPicker("red.700") }}
            weight={500}
          >
            {summaryData[columnID]}
          </Typography>
        </div>
      ) : null}
      {enableSorting && (
        <div className={`customSortIndicator ${sort}`}>
          {sort && <span>{sort === "asc" ? "▲" : "▼"}</span>}
        </div>
      )}
    </div>
  );
};

export default CustomAgHeader;
