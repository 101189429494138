import PageHeader from "app/shared/PageHeader";
import "./Reports.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { act, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import { Button, Select, Tag } from "antd";
import { AgTableClient } from "app/shared/AgTable";
import { useSearchParams } from "react-router-dom";
import ZAViewSelector from "app/shared/ZAViewSelector";
export default function Reports(props: any) {
  const [activeKey, setActiveKey] = useState("");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [searchParams] = useSearchParams();
  const [defaultView, setDefaultView] = useState<any[]>([
    {
      title: "Reports",
      id: 1,
      type: "TABLE",
    },
  ]);

  useEffect(() => {
    setActiveKey(userDetails.reports?.[0]?.embedurl);
  }, []);

  console.log("park", tabViews);

  const handleChangeView = (params: any) => {
    let viewId = Math.floor(1000 + Math.random() * 9000);
    setTabView({
      ...tabViews,
      reports: [
        ...tabViews.reports,
        {
          title: params.data?.report_name,
          type: "VIEW",
          viewData: params.data?.embedurl,
          id: viewId,
        },
      ],
    });
    // setActiveKey(viewId);
  };
  return (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          title="Reports"
          leftActions={
            <ZAViewSelector
              activeKey={activeKey}
              items={userDetails.reports || []}
              onViewChange={(data: any, fullObj: any) => setActiveKey(data)}
            />
          }
        />

        <div className="TableContainer">
          <div
            style={{
              height: "100%",
            }}
          >
            <iframe
              height="100%"
              style={{ width: "100%" }}
              scrolling="no"
              title="Iframe"
              src={activeKey}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
