import { Popconfirm, Progress } from "antd";
import Typography from "../Typography";
import "./GlobalUploader.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState, useRef } from "react";
import {
  API_ENDPOINT_STORAGE_UPLOAD_OBJECT_COMPLETE_UPLOAD,
  API_ENDPOINT_STORAGE_UPLOAD_OBJECT_CREATE_MULTIPART,
  API_ENDPOINT_STORAGE_UPLOAD_OBJECT_UPLOAD_PART,
} from "app/scenes/FinkAnalytics/finkanalytics.constants";
import { apiPost } from "app/services/apiServices";
import {
  uploadInfo,
  uploadProgressInfo,
  userInfo,
} from "app/config/States/users";
import { useRecoilState } from "recoil";

export default function GlobalUploader(props: any) {
  const [uploadProgress, setUploadProgress] = useState(0.0);
  const [uploadTimeRemaining, setUploadTimeRemaining] = useState<string | null>(
    null
  );
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [uploaderInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);
  const [_, setUploadProgressInfo] = useRecoilState<any>(uploadProgressInfo);

  const startTimeRef = useRef<Date | null>(null);

  const arrayBufferToBase64 = (buffer: any) => {
    // ArrayBuffer to Base64 conversion
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleBeforeUnload = (e: any) => {
    if (uploaderInfo) {
      const confirmationMessage =
        "Are you sure you want to leave? Changes you made may not be saved.";
      e.returnValue = confirmationMessage; // Required for modern browsers
      return confirmationMessage; // Some browsers display this as a fallback
    }
  };

  useEffect(() => {
    // Add the event listener when the component is mounted
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploaderInfo]);

  const calculateTimeRemaining = (uploadedSize: number) => {
    if (!startTimeRef.current) {
      return;
    }
    const elapsedTime =
      (new Date().getTime() - startTimeRef.current.getTime()) / 1000; // in seconds
    const averageSpeed = uploadedSize / elapsedTime; // bytes per second

    const remainingSize = uploaderInfo.file.size - uploadedSize;
    const remainingTime = remainingSize / averageSpeed; // in seconds
    // Convert remainingTime to a readable format (HH:MM:SS)
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = Math.floor(remainingTime % 60);

    setUploadTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
  };

  const handleFileUpload = async () => {
    console.log("bucekt info:", uploadInfo);
    if (!uploaderInfo?.file) {
      return;
    }

    startTimeRef.current = new Date();

    const uploadPartSize = 10 * 1024 * 1024; // Use larger part size like 10MB
    let start = 0;
    const fileKey = `${userDetails?.currentWorkspace?.id}/${uploaderInfo.file.name}`;
    const numParts = Math.ceil(uploaderInfo.file.size / uploadPartSize);
    let uploadId;
    let totalUploaded = 0;

    try {
      const createMultipartUploadRes = await apiPost(
        API_ENDPOINT_STORAGE_UPLOAD_OBJECT_CREATE_MULTIPART,
        {
          fileName: uploaderInfo.file.name,
          fileType: uploaderInfo.file.type,
          bucketName: uploaderInfo.bucketName || "raw-booking-data",
        }
      );

      uploadId = createMultipartUploadRes.data.UploadId;

      const uploadedParts = [];
      while (start < uploaderInfo.file.size) {
        const batchSize = Math.min(5, numParts - uploadedParts.length); // Upload 5 parts concurrently
        const promises = [];

        for (let i = 0; i < batchSize && start < uploaderInfo.file.size; i++) {
          const end = Math.min(start + uploadPartSize, uploaderInfo.file.size);
          const chunk = uploaderInfo.file.slice(start, end);
          let uploadProgresState = (start / uploaderInfo.file.size) * 100;
          setUploadProgress(uploadProgresState); // Update Progress
          setUploadProgressInfo(uploadProgresState);
          const arrayBuffer = await chunk.arrayBuffer();
          const chunkBase64 = arrayBufferToBase64(arrayBuffer);
          const partNumber: any = uploadedParts.length + 1;

          const uploadPartPromise = apiPost(
            API_ENDPOINT_STORAGE_UPLOAD_OBJECT_UPLOAD_PART,
            {
              Bucket: uploaderInfo.bucketName || "raw-booking-data",
              Key: fileKey,
              PartNumber: partNumber,
              UploadId: uploadId,
              PartBuffer: chunkBase64,
            }
          );
          uploadedParts.push({
            ETag: (await uploadPartPromise).data.ETag,
            PartNumber: partNumber,
          });

          promises.push(uploadPartPromise);
          start = end;
        }

        await Promise.all(promises);

        // Update the total uploaded size
        totalUploaded += batchSize * uploadPartSize;
        console.log("totalbatch", totalUploaded);
        calculateTimeRemaining(totalUploaded);
      }

      const completeMultipartUploadRes = await apiPost(
        API_ENDPOINT_STORAGE_UPLOAD_OBJECT_COMPLETE_UPLOAD,
        {
          Bucket: uploaderInfo.bucketName || "raw-booking-data",
          Key: fileKey,
          UploadId: uploadId,
          Parts: uploadedParts,
          datasetType: uploaderInfo?.dataset,
        }
      );
      setUploadProgress(100);
      setUploadTimeRemaining(null);
      setTimeout(() => {
        setUploadInfo(null);
      }, 1500);
      console.log("Final uploaded data:", completeMultipartUploadRes.data);
    } catch (err) {
      console.error("Error during multipart upload:", err);
    }
  };

  useEffect(() => {
    handleFileUpload();
  }, [uploaderInfo]);

  const handleCancelUpload = () => {
    setUploadInfo(null);
  };

  return uploaderInfo ? (
    <div className="GlobalUploader">
      <div className="Left">
        <Typography
          style={{ color: colorPicker("neutral.800") }}
          weight={500}
          variant="caption"
        >
          {uploaderInfo?.file?.name}
        </Typography>
        <Progress
          percent={parseFloat(uploadProgress?.toFixed(2))}
          status="active"
          strokeColor={colorPicker("primary.700")}
        />
        {uploadTimeRemaining && (
          <Typography
            style={{ color: colorPicker("neutral.600") }}
            weight={400}
            variant="xs"
          >
            Time remaining: {uploadTimeRemaining}
          </Typography>
        )}
      </div>
      <div className="Right">
        <Popconfirm
          title="Cancel Upload"
          description="Are you sure want to cancel this upload"
          onConfirm={handleCancelUpload}
          onCancel={() => null}
          okText="Yes"
          cancelText="No"
        >
          <CloseCircleFilled
            style={{ color: colorPicker("red.700"), cursor: "pointer" }}
          />
        </Popconfirm>
      </div>
    </div>
  ) : null;
}
