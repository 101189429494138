import { Button, Input, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import "./TableViewManager.scss";
import { colorPicker } from "app/utils/color.helper";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW,
  API_ENDPOINT_AG_TABLE_LOAD_VIEW,
  API_ENDPOINT_AG_TABLE_SAVE_VIEW,
} from "../AgTable/AgTable.constants";
import Typography from "../Typography";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { PlusOutlined, SaveOutlined, EditOutlined } from "@ant-design/icons";
export default function TableViewManagerV2(props: any) {
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [userDetails, _] = useRecoilState<any>(userInfo);

  const [views, setViews] = useState<any[]>([]);
  const [viewName, setViewName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedView, setSelectedView] = useState("DEFAULT");
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const getMenuItems = () => {};

  const fetchTableViews = async () => {
    const response = await apiPost(API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW, {
      moduleId: props.moduleId,
      reportId: props.reportId,
    });
    createSelectOptions(response.data);
  };

  const createSelectOptions = (rowData: any) => {
    let options: any = [
      {
        label: "Default View",
        value: "DEFAULT",
      },
    ];
    rowData.forEach((optionData: any) => {
      options.push({
        label: optionData.name,
        value: optionData.viewId,
      });
    });
    setViews(options);
  };
  // useEffect(() => {
  //   fetchTableViews();
  // }, [props.moduleId, userDetails.currentWorkspace]);

  const handleAddNewView = () => {
    setEditMode(true);
    messageApi.open({
      type: "info",
      content: "Please make your changes and save",
      duration: 5,
    });
  };

  const handleSaveNewView = () => {
    setShowModal(true);
  };
  const handleSaveView = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_AG_TABLE_SAVE_VIEW, {
      workspace_id: props.workspace_id,
      moduleId: "REPORT_MANAGER",
      workspace_name: props.workspace_name,
      name: viewName,
      reportId: props.reportId,
    });

    if (response.status) {
      props.updateView &&
        props.updateView(response.data.insertedId, "REPORT_MANAGER", viewName);
      setShowModal(false);
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
    // props.createView && props.createView(viewName);
    // setShowModal(false);
    // setViewName("");
    // setTimeout(() => {
    //   fetchTableViews();
    // }, 2000);
    setCreateMode(false);
    setLoading(false);
  };
  return (
    <div className="TableViewManagerNew">
      {/* <Select
        mode="multiple" // Enable multiple selection
        showSearch
        placeholder="Select State"
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: 240 }}
        options={views}
        onChange={(viewInfo: any) => {
          props.selectView && props.selectView(viewInfo);
          setSelectedView(viewInfo);
        }}
        size="small"
        optionLabelProp="label" // Ensures the label is displayed in the selection area
      /> */}
      {editMode ? (
        <>
          <Button
            size="small"
            onClick={() => {
              props.updateViewNew && props.updateViewNew(selectedView);
              setEditMode(false);
            }}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
          <Button
            size="small"
            danger
            style={{ marginLeft: 12 }}
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          {selectedView !== "DEFAULT" ? (
            <div
              className="eidtCircleButton"
              style={{ background: colorPicker("green.500") }}
            >
              <EditIcon
                style={{ fontSize: 15, color: "white" }}
                onClick={() => {
                  setEditMode(true);
                  messageApi.open({
                    type: "info",
                    content: "Please make your changes and save",
                    duration: 5,
                  });
                }}
              />
            </div>
          ) : null}

          {createMode ? (
            <>
              <Button
                size="small"
                onClick={handleSaveNewView}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
              <Button
                size="small"
                danger
                style={{ marginLeft: 12 }}
                onClick={() => setCreateMode(false)}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              {!props.viewMode ? (
                <Button
                  size="small"
                  onClick={() => setShowModal(true)}
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              ) : null}
              {props.selectedSheet !== "DATA" && !props.viewMode ? (
                <Button
                  size="small"
                  style={{ marginLeft: 12 }}
                  onClick={handleAddNewView}
                  icon={<EditOutlined />}
                >
                  Edit Sheet
                </Button>
              ) : null}
            </>
          )}
        </>
      )}
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        closable={false}
      >
        <Typography
          // variant="caption"
          style={{ color: colorPicker("neutral.800"), marginBottom: 12 }}
        >
          Create View
        </Typography>
        <Input
          placeholder="View Name"
          onChange={(e: any) => setViewName(e.target.value)}
          value={viewName}
        />
        <div
          style={{
            marginTop: 12,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{ marginRight: 12 }}
            onClick={() => setShowModal(false)}
            loading={isLoading}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveView} type="primary" loading={isLoading}>
            Save
          </Button>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
