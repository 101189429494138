import FinkAnalytics from "./FinkAnalytics";
import ColMapTablePreview from "./components/ColMapTablePreview";
import FASetup from "./components/FASetup";
import SchemaColmapHistory from "./components/SchemaColmapHistory";
import SchemaList from "./components/SchemaList";
import SchemaSource from "./components/SchemaSource";
import SchemaSourceDetail from "./components/SchemaSourceDetail";
import SchemaTablePreview from "./components/SchemaTablePreview";

//eslint-disable-next-line
export default {
  path: "/FA/",
  element: <FinkAnalytics />,
  strict: true,
  children: [
    {
      path: "/FA/schema/list",
      element: <SchemaList />,
      exact: true,
    },
    {
      path: "/FA/schema/edit/:schemaId",
      element: <FASetup editMode />,
      exact: true,
    },
    {
      path: "/FA/schema/source",
      element: <SchemaSource />,
      exact: true,
    },
    {
      path: "/FA/schema/source/detail/:sourceId",
      element: <SchemaSourceDetail />,
      exact: true,
    },
    {
      path: "/FA/schema/setup",
      element: <FASetup />,
      exact: true,
    },
    {
      path: "/FA/schema/columnmap",
      element: <FASetup />,
      exact: true,
    },
    {
      path: "/FA/schema/preview/:schemaId",
      element: <SchemaTablePreview />,
      exact: true,
    },
    {
      path: "/FA/schema/colmap/preview/:schemaId",
      element: <ColMapTablePreview />,
      exact: true,
    },
    {
      path: "/FA/schema/upload/history",
      element: <SchemaColmapHistory />,
      exact: true,
    },
  ],
};
