import { colorPicker } from "app/utils/color.helper";
import "./SchemaTablePreview.scss";
import { DatabaseOutlined } from "@ant-design/icons";
import Typography from "app/shared/Typography";
import { AgTableServer } from "app/shared/AgTable";
import PageHeader from "app/shared/PageHeader";
import { Button, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";

import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_FETCH_DB_TABLE_PREVIEW,
  API_ENDPOINT_FETCH_TABLE_DEFAULT_SERVER_PAYLOAD,
  API_ENDPOINT_SAVE_TABLE_CONFIG_VIEW,
  API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS,
} from "../../finkanalytics.constants";
import Loader from "app/shared/Loader";

export default function SchemaTablePreview(props: any) {
  const gridRef: any = useRef();
  const [messageApi, contextHolder] = message.useMessage();
  const [schemaInfo, setSchemaInfo] = useState<any>(null);
  const { schemaId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [defualtPayload, setDefaultPayload] = useState<any>(null);
  useEffect(() => {
    fetchSchemaDetail();
  }, []);

  const fetchSchemaDetail = async () => {
    setLoading(true);
    const response = await apiGet(
      `${API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS}/${schemaId}`
    );
    if (response.status) {
      setSchemaInfo(response.data);
      fetchDefaultServerPayload(response.data?.viewId, response.data?.moduleId);
    } else {
      setLoading(false);
    }
  };

  const fetchDefaultServerPayload = async (
    viewId: string,
    moduleId: string
  ) => {
    const payload = {
      schemaViewId: viewId,
      schemaModuleId: moduleId,
    };
    try {
    } catch (error) {}

    const response = await apiPost(
      API_ENDPOINT_FETCH_TABLE_DEFAULT_SERVER_PAYLOAD,
      payload
    );
    if (response.status) {
      if (response.data?.payload) {
        setDefaultPayload(response.data);
        gridRef.current && gridRef.current.applyTestView(response.data.payload);
      }
    }
    setLoading(false);
  };

  const handleSaveConfiguration = async () => {
    let payload = {
      table: schemaInfo?.internal_db_info?.table,
      view: gridRef.current && gridRef.current.getServerSidePayload(),
      viewname: schemaInfo?.name,
      schemaViewId: schemaInfo?.viewId,
      schemaModuleId: schemaInfo?.moduleId,
      database: schemaInfo?.internal_db_info?.database,
    };
    const response = await apiPost(
      API_ENDPOINT_SAVE_TABLE_CONFIG_VIEW,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Configuration is saved",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Something went wrong",
      });
    }
  };

  return (
    <div className="SchemaTablePreview">
      <div className="Header">
        <PageHeader
          title="Schema Table Preview"
          rightActions={
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSaveConfiguration}
            >
              Save Configuration
            </Button>
          }
        />
      </div>
      <div className="Content">
        <div className="MainContent">
          <div className="TableListContainer">
            {isLoading ? (
              <Loader />
            ) : (
              <AgTableServer
                //@ts-ignore

                columnDefs={schemaInfo?.state?.columnDefs}
                ref={gridRef}
                endpoint={API_ENDPOINT_FETCH_DB_TABLE_PREVIEW}
                autoResize
                serverPayload={{
                  database: schemaInfo?.internal_db_info?.database,
                  dbType: schemaInfo?.internal_db_info?.dbType,
                  table: schemaInfo?.internal_db_info?.table,
                }}
                toolPanelProps={{
                  schemaViewId: schemaInfo?.viewId,
                  schemaModuleId: schemaInfo?.moduleId,
                  view_id: defualtPayload?.viewid,
                  crmInfo: defualtPayload,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
