import React, { useEffect, useMemo, useState } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
import WorkspaceModal from './WorkspaceMap'; // Assuming this is your modal component
import { AgTableClient } from 'app/shared/AgTable';
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [clusters, setClusters] = useState([]); // Store clusters
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user data
  const [isModalOpen, setModalOpen] = useState(false); // Control modal state

  // Fetch users and clusters simultaneously
  const fetchData = async () => {
    try {
      const [usersResponse, clustersResponse] = await Promise.all([
        fetch('https://stage-apiplatform.finkraft.ai/api/meta/users'), // Adjust your API endpoint for users
        fetch('https://stage-apiplatform.finkraft.ai/api/meta/cluster/list') // Cluster API endpoint
      ]);

      const usersData = await usersResponse.json();
      const clustersData = await clustersResponse.json();

      // Store users and clusters
      setUsers(usersData.data);
      setClusters(clustersData.data); // Save cluster list (assuming `data` key holds clusters)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Helper function to get cluster name by cluster_id
  const getClusterName = (cluster_id) => {
    const cluster = clusters.find(cluster => cluster.id === cluster_id);
    return cluster ? cluster.name : ''; // Return 'Unknown Cluster' if not found
  };

  // Define columns for ag-Grid
  const columnDefs = useMemo(() => [
    { field: 'id', headerName: 'ID',  enableRowGroup: true, width: "300" },
    { field: 'name', headerName: 'Name',  enableRowGroup: true, width: "200", },
    { field: 'email', headerName: 'Email',  enableRowGroup: true, },
    { field: 'cluster_id', headerName: 'Cluster ID',  enableRowGroup: true, width: "300" },
    { 
      field: 'cluster_name', 
      headerName: 'Cluster Name', 
       
      valueGetter: (params) => {
        // Check if params.data exists and has cluster_id
        return params.data && params.data.cluster_id 
          ? getClusterName(params.data.cluster_id) 
          : ''; // Return empty string if not found
      }, 
      enableRowGroup: true,
    },    
    {
      field: 'no_of_workspaces',
      headerName: 'No. of Workspaces',
      enableRowGroup: true,
      valueGetter: (params) => {
        // Return 0 if no_of_workspaces is undefined or null
        return params?.data?.no_of_workspaces !== undefined ? params.data.no_of_workspaces : 0;
      },
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              setSelectedUser({
                userId: params.data.id,
                clusterId: params.data.cluster_id,
                username: params.data.name,
                useremail: params.data.email
              });
              setModalOpen(true); // Open the modal
            }}
          >
            {params.data?.no_of_workspaces !== undefined ? params.data.no_of_workspaces : 0}
          </Typography>
        );
      },
    },    
    { field: 'user_type_id', headerName: 'User Type ID',  enableRowGroup: true, },
    { field: 'created_at', headerName: 'Created At',  enableRowGroup: true, },
  ], [clusters]); // Add `clusters` as a dependency to re-compute when clusters are fetched



  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
    setSelectedUser(null); // Clear selected user data
    fetchData(); // Refresh user data after closing modal
  };

  return (
    <div>
      <h2  style={{marginBottom:'20px' }}>User Table </h2>
      <div className="ag-theme-alpine" style={{ height: '100vh', width: '100%' }}>
        <AgTableClient
          rowData={users}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
          }}
          groupPanel
        />
      </div>

      {isModalOpen && selectedUser && (
        <WorkspaceModal 
          userId={selectedUser.userId} 
          clusterId={selectedUser.clusterId} 
          username={selectedUser.username}
          useremail={selectedUser.useremail}
          onClose={handleCloseModal} 
          isOpen={isModalOpen} 
        />
      )}
    </div>
  );
};

export default UserTable;
