import PageHeader from "app/shared/PageHeader";
import React, { useEffect, useRef, useState } from "react";
import "./ReportCreate.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, List, message, Modal, Select } from "antd";
import { API_ENDPOINT_FETCH_DB_TABLES } from "app/scenes/FinkCRM/fink.crm.constants";
import { apiGet, apiPost } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import EmptyState from "app/shared/EmptyState";
import { AgTableClient } from "app/shared/AgTable";
import { DatabaseOutlined } from "@ant-design/icons";
import SearchInput from "app/shared/SearchInput";
import { API_ENDPOINT_REPORT_MANAGER_CREATE } from "../../report.manager.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

export default function ReportCreate(props: any) {
  const [sourceData, setSourceData] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [zaId, setZAId] = useState("");
  const [reportName, setReportName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [creating, setCreating] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);

  let sourceId = "mongodb";
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const gridRef: any = useRef();
  useEffect(() => {
    if (sourceId === "zohoanalytics") return;
    fetchTableOFDB();
  }, []);
  const fetchTableOFDB = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_DB_TABLES);
    if (response.status) {
      setSourceData(response.data);
    }
    setLoading(false);
  };

  const sourceNameMap: any = {
    postgre: "Postgre",
    mongodb: "MongoDB",
    zohoanalytics: "Zoho Analytics",
  };

  const getTableDataProcessed = (data: any) => {
    let rowMainData: any = [];
    data.forEach((element: any, key: any) => {
      rowMainData.push({
        sno: key + 1,
        table_name: element,
      });
    });
    return rowMainData;
  };

  const handleSearchWorkspace = (query: string) => {
    if (gridRef.current && gridRef.current.onTableSearch) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  const handleZASchema = () => {
    if (zaId) {
      navigate("/FA/schema/setup", {
        state: { zaId: zaId, sourceType: sourceId },
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Please enter your valid ZA Info",
      });
    }
  };

  const handleCreateReport = async () => {
    setCreating(true);

    const response = await apiPost(API_ENDPOINT_REPORT_MANAGER_CREATE, {
      workspace_id: selectedWorkspace?.[0]?.value,
      report_name: reportName,
      created_by: userDetails?.name || userDetails?.email,
      workspace_name: selectedWorkspace?.[0]?.name,
      dbType: sourceId,
      database: selectedDatabase,
      table: selectedTable?.table_name,
    });
    if (response.status) {
      // navigate("/reportmanager/view/" + response.data?.insertedId, {
      //   state: {
      //     dbType: sourceId,
      //     database: selectedDatabase,
      //     table: selectedTable?.table_name,
      //     report_name: reportName,
      //   },
      // });
      navigate("/reportmanager/create/view/schema/" + response.data?.id, {
        state: {
          dbType: sourceId,
          database: selectedDatabase,
          table: selectedTable?.table_name,
          report_name: reportName,
          selectedWorkspaceId: selectedWorkspace?.[0]?.value,
        },
      });
    } else {
    }
    setCreating(false);
  };

  const { Option } = Select;

  const handleSelectWorkspace = (workspaceInfo: any, workspaceFullObj: any) => {
    setSelectedWorkspace(workspaceFullObj);
  };
  return (
    <div className="SchemaSourceDetail">
      <div className="Header">
        <PageHeader
          goBack
          title={`${sourceNameMap?.[sourceId || ""]} Configuration`}
          rightActions={
            <SearchInput
              placeholder="Search Table"
              onSearch={handleSearchWorkspace}
            />
          }
        />
      </div>

      <div className="MainContent">
        {isLoading ? (
          <Loader />
        ) : sourceId !== "zohoanalytics" ? (
          <>
            <div className="DatabaseListContainer">
              <List
                size="small"
                dataSource={
                  Object.keys(sourceData?.[sourceId || ""] || {}) || []
                }
                renderItem={(item) => (
                  <List.Item onClick={() => setSelectedDatabase(item)}>
                    <DatabaseOutlined
                      style={{
                        marginRight: 4,
                        color: colorPicker("neutral.800"),
                      }}
                    />
                    {item}
                  </List.Item>
                )}
              />
            </div>
            <div className="TableViewContainer">
              {selectedDatabase ? (
                <AgTableClient
                  //@ts-ignore
                  rowData={getTableDataProcessed(
                    sourceData?.[sourceId || ""]?.[selectedDatabase] || []
                  )}
                  ref={gridRef}
                  hideToolbar
                  columnDefs={[
                    { field: "sno", headerName: "S.NO" },
                    { field: "table_name", headerName: "Table Name" },
                    {
                      field: "actions",
                      headerName: "Actions",
                      cellRenderer: (params: any) => {
                        return (
                          <div>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                setShowModal(true);
                                setSelectedTable(params.data);
                              }}
                            >
                              Create Report
                            </Button>
                          </div>
                        );
                      },
                    },
                  ]}
                  autoResize
                />
              ) : (
                <EmptyState />
              )}
            </div>
          </>
        ) : (
          <div className="ThirdPartySourceContainer">
            <Input
              style={{ width: 400 }}
              placeholder="Enter your ZA Table or View ID"
              onChange={(e: any) => setZAId(e.target.value)}
            />
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              onClick={handleZASchema}
            >
              Generate Schema
            </Button>
          </div>
        )}
      </div>
      {contextHolder}

      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        title="Report Create"
      >
        <div style={{ marginTop: 44 }}>
          <div style={{ marginBottom: 12 }}>
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              mode="multiple"
              placeholder="Select Workspace"
              defaultValue={[]}
              onChange={handleSelectWorkspace}
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().includes(input.toLowerCase()) ||
                option.value.toLowerCase().includes(input.toLowerCase())
              }
            >
              {userDetails.workspaces.map((opt: any) => (
                <Option
                  key={opt.id}
                  value={opt.id}
                  meta={opt.meta}
                  domain={opt.domains}
                  name={opt.name}
                >
                  {opt.name}
                </Option>
              ))}
            </Select>
          </div>
          {selectedWorkspace ? (
            <>
              <Input
                placeholder="Report Name"
                onChange={(e: any) => setReportName(e.target.value)}
              />
              <div
                style={{
                  marginTop: 24,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  type="primary"
                  onClick={handleCreateReport}
                  loading={creating}
                >
                  Create Report
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
