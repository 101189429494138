import { colorPicker } from "app/utils/color.helper";
import "./ColMapTablePreview.scss";
import { DatabaseOutlined } from "@ant-design/icons";
import Typography from "app/shared/Typography";
import { AgTableServer } from "app/shared/AgTable";
import PageHeader from "app/shared/PageHeader";
import { Badge, Button, message, Popover, Tabs } from "antd";
import { act, useEffect, useRef, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import { WarningOutlined, MonitorOutlined } from "@ant-design/icons";

import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_FETCH_DB_TABLE_PREVIEW,
  API_ENDPOINT_FETCH_TABLE_DATA_FOR_UPLOADED_FILE,
} from "../../finkanalytics.constants";
import Loader from "app/shared/Loader";
import AgTableClient from "app/shared/AgTable/AgTableClient";
import CustomAgHeader from "./CustomHeader";
import CustomReconTabs from "app/scenes/Reconcilation/components/CustomReconTabs";
import { FormattedNumber } from "react-intl";

export default function ColMapTablePreview(props: any) {
  const gridRef: any = useRef();
  const [messageApi, contextHolder] = message.useMessage();
  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const { schemaId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [defualtPayload, setDefaultPayload] = useState<any>(null);
  const [errorSummary, setErrorSummary] = useState<any>(null);
  const [activePageTab, setActivePageTab] = useState("1");
  const [filterApplied, setFilterApplied] = useState(false);
  const [errorRows, setErrorRows] = useState<any[]>([]);
  const [cleanRows, setCleanRows] = useState<any>([]);
  useEffect(() => {
    fetchTableData();
  }, []);

  const handleFilterInvalid = async () => {
    setFilteredData(errorRows);
    setFilterApplied(true);
  };
  const handleClearAllFilter = () => {
    setFilterApplied(false);
    setFilteredData(tableData);
  };
  const generateColumnDefs = (data: any) => {
    // Define keys to ignore
    const ignoreKeys = ["valid", "fail_reason", "raw_data"];

    // Extract keys from the first object in the data and filter out the ignored ones
    const columnDefs = Object.keys(data.rows[0] || {})
      .filter((key) => !ignoreKeys.includes(key))
      .map((key) => {
        return {
          headerName: key.replace(/_/g, " ").toUpperCase(),
          field: key,
          sortable: true,
          filter: true,
          resizable: true,
          width: 250,
          minWidth: 250,
          headerComponent: (params: any) => (
            <CustomAgHeader {...params} applyFilter={handleFilterInvalid} />
          ),
          summaryData: data.summary,
          cellRenderer: (params: any) => (
            <div
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 12,
                paddingRight: 12,
                backgroundColor:
                  params.data?.fail_reason && params.data?.fail_reason?.[key]
                    ? "#fee9eb"
                    : params.data?.fail_reason &&
                      params.data?.fail_reason["error"] === "DUPLICATE"
                    ? "#ffeaa7"
                    : "none",
              }}
            >
              <Typography style={{ height: "100%" }}>
                {(params.data?.fail_reason &&
                  params.data?.fail_reason?.[key]) ||
                params.data?.fail_reason?.["error"] == "DUPLICATE" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <small style={{ width: "80%", overflow: "hidden" }}>
                      {params.data?.[key] || "Missing"}
                    </small>

                    <Popover
                      placement="bottom"
                      title={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WarningOutlined
                            style={{
                              color: colorPicker("red.700"),
                              display: "flex",
                              justifyContent: "center",
                              cursor: "pointer",
                              marginRight: 4,
                            }}
                          />
                          <Typography
                            variant="xs"
                            style={{ color: colorPicker("red.700") }}
                          >
                            {params.data?.fail_reason?.[key] ||
                              params.data?.fail_reason?.error}
                          </Typography>
                        </div>
                      }
                      content={
                        <div>
                          <Typography
                            variant="xs"
                            style={{ color: colorPicker("neutral.800") }}
                          >
                            This column has duplicate data in our system
                          </Typography>
                          <Typography
                            variant="xs"
                            weight={500}
                            style={{
                              color: colorPicker("primary.700"),
                              marginTop: 8,
                            }}
                          >
                            <MonitorOutlined
                              style={{
                                color: colorPicker("primary.700"),
                                marginRight: 4,
                                fontSize: 18,
                              }}
                            />
                            Suggested fix
                          </Typography>
                          <Button
                            size="small"
                            type="primary"
                            style={{ marginTop: 16, width: "100%" }}
                          >
                            Fix this column
                          </Button>
                        </div>
                      }
                    >
                      {params.data?.fail_reason?.["error"] ==
                      "DUPLICATE" ? null : (
                        <WarningOutlined
                          style={{
                            color: colorPicker("red.700"),
                            width: "20%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Popover>
                  </div>
                ) : (
                  params.data[key]
                )}
              </Typography>
            </div>
          ),
        };
      });
    setFilteredData(data.rows);
    setTableData(data.rows);
    setColumnDefs(columnDefs);
    let errorRows = (data.rows || []).filter(
      (item: any) =>
        Object.entries(item.fail_reason).length > 0 && !item.fail_reason.error
    );
    setErrorRows(errorRows);
    let cleanRows = (data.rows || []).filter(
      (item: any) => item.valid || item.fail_reason.error === "DUPLICATE"
    );
    setCleanRows(cleanRows);
    setLoading(false);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const response = await apiGet(
      `${API_ENDPOINT_FETCH_TABLE_DATA_FOR_UPLOADED_FILE}${schemaId}`
    );
    if (response.status) {
      generateColumnDefs(response.data);

      setErrorSummary(response.data.summary);
    } else {
      setLoading(false);
    }
  };

  const handleDownloadTableData = () => {
    gridRef.current && gridRef.current.exportDataToExcel();
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div
            style={{ display: "flex", alignItems: "center", marginRight: 8 }}
          >
            All Rows
          </div>
          <div
            style={{
              background: colorPicker("neutral.300"),
              marginLeft: 6,
              color: colorPicker("primary.700"),
              fontSize: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              borderRadius: 3,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <FormattedNumber value={tableData.length} notation="compact" />
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            Clean Rows
          </div>
          <div
            style={{
              background: colorPicker("neutral.300"),
              marginLeft: 6,
              color: colorPicker("primary.700"),
              fontSize: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              borderRadius: 3,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <FormattedNumber
              value={tableData.length - errorRows.length}
              notation="compact"
            />
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            Error Rows
          </div>
          <div
            style={{
              width: 14,
              height: 14,
              background: colorPicker("red.200"),
              marginLeft: 8,
              color: colorPicker("red.700"),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              borderRadius: 3,
              paddingLeft: 2,
              paddingRight: 2,
              fontSize: 10,
            }}
          >
            {errorRows.length}
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="ColMapTablePreview">
      <div className="Header">
        <PageHeader
          title="Schema Table Preview"
          goBack
          rightActions={
            <>
              {filterApplied ? (
                <Button size="small" onClick={handleClearAllFilter}>
                  Clear Filter
                </Button>
              ) : null}
              <Button
                size="small"
                type="primary"
                style={{ marginLeft: 24 }}
                onClick={handleDownloadTableData}
              >
                Download Error Report
              </Button>
            </>
          }
        />
      </div>
      <div className="Content">
        <div className="MainContent">
          <div className="TabContent">
            <Tabs
              items={items}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activePageTab}
              onChange={(tabItem: string) => setActivePageTab(tabItem)}
            />
          </div>

          <div className="TableListContainer">
            {isLoading ? (
              <Loader />
            ) : (
              <AgTableClient
                //@ts-ignore

                columnDefs={columnDefs}
                rowData={
                  filterApplied
                    ? errorRows
                    : activePageTab === "1"
                    ? filteredData
                    : activePageTab === "2"
                    ? cleanRows
                    : errorRows
                }
                ref={gridRef}
                autoResize
                showStatusBar
              />
            )}
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
