import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./App.css"; // Import your CSS file for custom modal styles
import PanModal from "./updateworkspace";
import CreateWorkspaceModal from "./CreateWorkspace";
import { colorPicker } from "app/utils/color.helper";
import Typography from "app/shared/Typography";
// // import "ag-grid-enterprise";
// import { AgGridReact } from "ag-grid-react";
// import "ag-grid-enterprise/styles/ag-grid.css";
// import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import GstinModel from "./GstinModel";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { AgTableClient } from "app/shared/AgTable";

Modal.setAppElement("#root");

const ReportTable = () => {
  const [rowData, setRowData] = useState([]);
  const [isPanModalOpen, setIsPanModalOpen] = useState(false);
  const [panCount, setPanCount] = useState(0);
  const [panCounts, setPanCounts] = useState({});
  const [existingBoxFolderId,setBoxfolderid] = useState("")
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
  const [workspaceName,setWorkspaceName] = useState(null)
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Workspace ID",
      field: "workspace_id",
      filter: true,
      width: "400",
      enableRowGroup: true
    },
    {
      headerName: "Workspace Name",
      field: "workspace_name",
      filter: true,
      width: "400",
      enableRowGroup: true
    },
    {
      headerName: "Is Active",
      field: "is_active",
      enableRowGroup: true,
      cellRendererFramework: (params) => {
        const isActive = params.value !== undefined && params.value !== null ? params.value : false;
        return (
          <input
            type="checkbox"
            checked={isActive}
            readOnly // Prevent toggle
          />
        );
      },
      valueGetter: (params) => {
        if (params.node.group) return null; 
        return params.data ? params.data.is_active : false;
      }
    },
    {
      headerName: "GSTIN Count",
      field: "gstin_count",
      filter: true,
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              setSelectedWorkspaceId(params.data.workspace_id);
              setWorkspaceName(params.data.workspace_name);
              setIsGstinModalOpen(true); // Open the GSTIN modal
            }}
          >
            {params.data?.gstin_count}
          </Typography>
        );
      },
      width: "200",
      enableRowGroup: true,
    },
    {
      headerName: "PAN Count",
      field: "pan_count",
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              setSelectedWorkspaceId(params.data.workspace_id);
              setPanCount(params.data.pan_count); 
              setExistingName(params.data.workspace_name);
              setExistingIsActive(params.data.is_active);
              setBoxfolderid(params.data.workspace_box_id); 
              setWorkspaceName(params.data.workspace_name);
              setIsPanModalOpen(true); // Open the PAN modal
            }}
          >
            {params.data?.pan_count}
          </Typography>
        );
      },
      width: "200",
      enableRowGroup: true
    },
    {
      headerName: "Workspace Box ID",
      field: "workspace_box_id",
      filter: true,
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              setSelectedWorkspace(params.data);
              setWorkspaceName(params.data.workspace_name);
              setFormData({ box_folder_id: params.data.box_folder_id });
              setIsBoxFormOpen(true); // Open the Box Folder modal
            }}
          >
            {params.data?.workspace_box_id}
          </Typography>
        );
      },
      width: "300",
      enableRowGroup: true
    },
    {
      headerName: "box_folder_id",
      field: "box_folder_id",
      filter: true,
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              setSelectedWorkspace(params.data);
              setWorkspaceName(params.data.workspace_name);
              setFormData({ box_folder_id: params.data.box_folder_id });
              setIsBoxFormOpen(true); // Open the Box Folder modal
            }}
          >
            {params.data?.box_folder_id}
          </Typography>
        );
      },
      width: "300",
      enableRowGroup: true
    },
    {
      headerName: "Reports",
      field: "reports",
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              const dataWithBoxFolderId = params.data.reports.map(item => ({
                ...item,
              }));
              setModalData(dataWithBoxFolderId);
              setViewType("reports");
              setSelectedWorkspace(params.data);
              setWorkspaceName(params.data.workspace_name);
              setIsModalOpen(true);
            }}
          >
            {params.data?.reports.length}
          </Typography>
        );
      },
      filter: true,
      width: "200"
    },
    {
      headerName: "Dashboards",
      field: "dashboards",
      cellRenderer: (params) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              const dataWithBoxFolderId = params.data.dashboards.map(item => ({
                ...item,
              }));
              setModalData(dataWithBoxFolderId);
              setViewType("dashboards");
              setSelectedWorkspace(params.data);
              setWorkspaceName(params.data.workspace_name);
              setIsModalOpen(true);
            }}
          >
            {params.data?.dashboards.length}
          </Typography>
        );
      },
      filter: true,
      width: "200"
    }
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isBoxFormOpen, setIsBoxFormOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [viewType, setViewType] = useState(""); // 'reports' or 'dashboards'
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    embedurl: "",
    report_name: "",
    report_number: "",
    box_folder_id: "",
  });
  const [editingItem, setEditingItem] = useState(null); // For update functionality



  
  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        // Fetch workspace data
        const workspaceResponse = await fetch("https://apiplatform.finkraft.ai/meta/workspaces");
        const workspaceData = await workspaceResponse.json();

        // Fetch report data
        const reportResponse = await fetch("https://stage-apiplatform.finkraft.ai/api/report/reports");
        const reportData = await reportResponse.json();

        // Fetch GSTIN counts
        const gstinResponse = await fetch("https://stage-apiplatform.finkraft.ai/api/meta/totalgstins");
        const gstinData = await gstinResponse.json();

        // Fetch PAN counts
        const panResponse = await fetch("https://stage-apiplatform.finkraft.ai/api/meta/panscount");
        const panData = await panResponse.json();

        // Create a mapping for GSTIN counts
        const gstinCountMap = {};
        gstinData.data.forEach(item => {
          gstinCountMap[item.workspace_name] = item.total_gstin_count;
        });

        // Create a mapping for PAN counts
        const panCountMap = {};
        panData.forEach(item => {
          panCountMap[item.workspace_id] = item.pancount;
        });

        // Fetch counts for each workspace and map the results
        const updatedData = await Promise.all(
          workspaceData.map(async (workspace) => {
            // Find the report by matching workspace_id
            const report = reportData.find((r) => r.workspace_id === workspace.id);

            // Initialize box_folder_id and other counts
            const boxFolderId = report ? report.box_folder_id : "N/A";
            const gstinCount = gstinCountMap[workspace.name] || "0"; // Get GSTIN count from map
            const panCount = panCountMap[workspace.id] || "0"; // Get PAN count from map

            return {
              workspace_id: workspace.id, // Show workspace_id
              workspace_name: workspace.name, // Show workspace name
              is_active: workspace.is_active, // Show is_active
              workspace_box_id: workspace.box_folder_id || "N/A", // Show workspace_box_id
              reports: report ? report.reports : [], // Map report data if available
              dashboards: report ? report.dashboards : [], // Map dashboard data if available
              gstin_count: gstinCount, // Add the count of GSTINs
              pan_count: panCount, // Add the count of PANs
              box_folder_id: boxFolderId, // Add the box_folder_id from report
            };
          })
        );

        // Update the state with the modified data
        setRowData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchWorkspaces();
  }, []);



  
  

  const [panData, setPanData] = useState([]);
  const [existingName, setExistingName] = useState("");
  const [existingIsActive, setExistingIsActive] = useState(false);
  const [isGstinModalOpen, setIsGstinModalOpen] = useState(false);

  useEffect(() => {
    if (isPanModalOpen && selectedWorkspace) {
      fetch(
        `https://apiplatform.finkraft.ai/meta/pans/${selectedWorkspace.workspace_id}`
      )
        .then((response) => response.json())
        .then((data) => {
          setPanData(data); // Assuming data is an array of PAN objects
        })
        .catch((error) => {
          console.error("Error fetching PAN data:", error);
        });
    }
  }, [isPanModalOpen, selectedWorkspace]);

  const onCellClicked = (params) => {
    if (params.colDef.field === "pan_count") {
      setSelectedWorkspaceId(params.data.workspace_id);
      setPanCount(params.data.pan_count); 
      setExistingName(params.data.workspace_name);
      setExistingIsActive(params.data.is_active);
      setBoxfolderid(params.data.workspace_box_id) 
      setWorkspaceName(params.data.workspace_name)
      setIsPanModalOpen(true); // Open the PAN modal
    }
    else if (params.colDef.field === "gstin_count") {
        setSelectedWorkspaceId(params.data.workspace_id);
        setWorkspaceName(params.data.workspace_name)
        setIsGstinModalOpen(true); // Open the GSTIN modal
      }
    else if (params.colDef.field === "box_folder_id") {
      setSelectedWorkspace(params.data);
      setWorkspaceName(params.data.workspace_name)
      setFormData({ box_folder_id: params.data.box_folder_id });
      setIsBoxFormOpen(true);
    } else if (
      params.colDef.field === "reports" ||
      params.colDef.field === "dashboards"
    ) {
      const dataWithBoxFolderId = params.data[params.colDef.field].map(
        (item) => ({
          ...item,
        })
      );

      setModalData(dataWithBoxFolderId);
      setViewType(params.colDef.field);
      setSelectedWorkspace(params.data);
      setWorkspaceName(params.data.workspace_name)
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedWorkspace(null);
    setViewType("");
    setEditingItem(null); // Reset editing item when closing modal
  };

  const openForm = (item) => {
    setFormData(item);
    setEditingItem(item);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsBoxFormOpen(false);
    setFormData({
      embedurl: "",
      report_name: "",
      report_number: "",
      box_folder_id: "",
    });
    setEditingItem(null); // Clear editing item when closing form
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const endpoint = viewType === "reports" ? "/addReport" : "/addDashboard";

    try {
      const response = await fetch(
        `https://stage-apiplatform.finkraft.ai/api/report${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            workspace_id: selectedWorkspace.workspace_id,
          }),
        }
      );

      if (response.ok) {
        const newItem = await response.json();

        // Update the modal data with the new report/dashboard
        setModalData((prevData) => [...prevData, newItem]);

        // Update the corresponding row in rowData
        setRowData((prevRowData) =>
          prevRowData.map((row) =>
            row.workspace_id === selectedWorkspace.workspace_id
              ? {
                  ...row,
                  [viewType]: [...row[viewType], newItem], // Update the count for reports/dashboards
                }
              : row
          )
        );
        closeForm();
      } else {
        console.error("Failed to add item");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const endpoint =
      viewType === "reports" ? "/updateReport" : "/updateDashboard";
    const body = {
      ...formData,
      workspace_id: selectedWorkspace.workspace_id,
      old_embedurl: editingItem.embedurl, // Send old embedurl to identify the item to be updated
    };

    try {
      const response = await fetch(
        `https://stage-apiplatform.finkraft.ai/api/report${endpoint}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        const updatedItem = await response.json();

        // Update the modal data with the updated item
        setModalData((prevData) =>
          prevData.map((item) =>
            item.embedurl === editingItem.embedurl ? updatedItem : item
          )
        );

        // Update the corresponding row in rowData
        setRowData((prevRowData) =>
          prevRowData.map((row) =>
            row.workspace_id === selectedWorkspace.workspace_id
              ? {
                  ...row,
                  [viewType]: row[viewType].map((item) =>
                    item.embedurl === editingItem.embedurl ? updatedItem : item
                  ),
                }
              : row
          )
        );
        closeForm();
      } else {
        console.error("Failed to update item");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (data) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmDelete) {
      const endpoint =
        viewType === "reports" ? "/deleteReport" : "/deleteDashboard";

      try {
        const response = await fetch(
          `https://stage-apiplatform.finkraft.ai/api/report${endpoint}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              workspace_id: selectedWorkspace.workspace_id,
              embedurl: data.embedurl,
            }),
          }
        );

        if (response.ok) {
          // Remove the deleted item from modal data
          setModalData((prevData) =>
            prevData.filter((item) => item.embedurl !== data.embedurl)
          );

          // Decrease the count in rowData
          setRowData((prevRowData) =>
            prevRowData.map((row) =>
              row.workspace_id === selectedWorkspace.workspace_id
                ? {
                    ...row,
                    [viewType]: row[viewType].filter(
                      (item) => item.embedurl !== data.embedurl
                    ),
                  }
                : row
            )
          );
        } else {
          console.error("Failed to delete item:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };


  const handleEditClick = (item) => {
    openForm(item);
  };


  const handleUpdateBoxFolderId = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://stage-apiplatform.finkraft.ai/api/report/updateBoxFolderId`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspace_id: selectedWorkspace.workspace_id,
            box_folder_id: formData.box_folder_id,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        // Validate the response data
        if (responseData && typeof responseData.box_folder_id === "number") {
          const newBoxFolderId = responseData.box_folder_id;

          // Update the rowData state with the new box_folder_id
          setRowData((prevData) =>
            prevData.map((item) =>
              item.workspace_id === selectedWorkspace.workspace_id
                ? { ...item, box_folder_id: newBoxFolderId }
                : item
            )
          );
          closeForm();
        } else {
          console.error("Invalid response data:", responseData);
        }
      } else {
        console.error("Failed to update box_folder_id");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] =
    useState(false);

  const handleOpenCreateWorkspaceModal = () => {
    setIsCreateWorkspaceModalOpen(true);
  };

  const handleCloseCreateWorkspaceModal = () => {
    setIsCreateWorkspaceModalOpen(false);
  };

  const handleWorkspaceSubmit = (formData) => {
    console.log(formData);
    
    // Close the modal
    setIsCreateWorkspaceModalOpen(false);

    // Construct the new workspace object
    const newWorkspace = {
      name: formData.name,
      is_active: formData.is_active,
      workspace_box_id: formData.box_folder_id,
    };

    // Update rowData with the new workspace
    setRowData((prevRowData) => [...prevRowData, newWorkspace]);
  };

  const modalColumns = [
    { headerName: "Embed URL", field: "embedurl",enableRowGroup: true, sortable: true, },
    {
      headerName: viewType === "reports" ? "Report Name" : "Dashboard Name",
      field: "report_name",enableRowGroup: true, sortable: true,
    },
    {
      headerName: viewType === "reports" ? "Report Number" : "Dashboard Number",
      field: "report_number",enableRowGroup: true, sortable: true,
    },
    {
      headerName: "Actions",enableRowGroup: true, sortable: true,
      cellRenderer: (params) => (
        <div>
          <button
            className="edit-button"
            onClick={() => handleEditClick(params.data)}
          >
            Edit
          </button>
          <button
            className="delete-button"
            onClick={() => handleDelete(params.data)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

    // Function to fetch the latest PAN counts for all workspaces
    const fetchPanCounts = async () => {
      try {
        const panResponse = await fetch("https://stage-apiplatform.finkraft.ai/api/meta/panscount");
        const panData = await panResponse.json();
        
        // Create a mapping of workspace_id to pan_count
        const panCountMap = {};
        panData.forEach(item => {
          panCountMap[item.workspace_id] = item.pancount;
        });
  
        // Update the rowData with the new PAN counts
        setRowData((prevRowData) =>
          prevRowData.map((row) => ({
            ...row,
            pan_count: panCountMap[row.workspace_id] || "0", // Use the new PAN count or default to "0"
          }))
        );
      } catch (error) {
        console.error("Error fetching PAN counts:", error);
      }
    };
  


  const handlePanModalUpdate = async () => {
    // Fetch and update PAN counts for all workspaces
    await fetchPanCounts();
  };



  return (
    <div>
     <div className="button-container">
     <Button
      type="primary"
      size="medium"
       
        onClick={handleOpenCreateWorkspaceModal}
      >
        Add Workspace
      </Button>
      <Link to="/settings/add-gstin">
          <Button  type="primary"
              size="medium">ADD GSTIN</Button>
      </Link>
      <Link to="/settings/user-table">
          <Button  type="primary"
              size="medium">User Table</Button>
      </Link>
     </div>
      <div className="TableContainer" style={{ height: "100vh" }}>
        <AgTableClient
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ filter: true }}
          groupPanel
          
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Reports/Dashboards Modal"
        className="custom-modal-report"
      >
        <h2>
          {viewType === "reports"
            ? `Report for Workspace : ${workspaceName}`
            : `Dashboard for Workspace : ${workspaceName}`}
        </h2>
        <div style={{ height: "50vh", width: '100%', }}>
          <AgTableClient rowData={modalData} columnDefs={modalColumns} defaultColDef={{ filter: true }}
          groupPanel />
        </div>
        <div className="form-buttons">
          <button className="modal-button" onClick={() => setIsFormOpen(true)}>
            Add
          </button>
          <button className="modal-button" onClick={closeModal}>
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isBoxFormOpen}
        onRequestClose={closeForm}
        contentLabel="Add/Edit Box Folder ID"
        className="custom-form-modal-report"
      >
        <h3>{`Update Box Folder ID for Workspace: ${workspaceName}`}</h3>
        <form onSubmit={handleUpdateBoxFolderId} className="report-form">
          <label className="report-label">Box Folder ID:</label>
          <input
            className="report-input"
            type="text"
            name="box_folder_id"
            value={formData.box_folder_id}
            onChange={handleInputChange}
            required
          />

          <div className="report-buttons">
            <button type="submit" className="modal-submit-button">Update</button>
            <button type="button" onClick={closeForm} className="modal-close-button">
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={isFormOpen}
        onRequestClose={closeForm}
        contentLabel="Add/Edit Form"
        className="custom-form-modal-report"
      >
        <h2>{editingItem ? "Edit Item" : "Add Item"}</h2>
        <form
          onSubmit={editingItem ? handleUpdate : handleAdd}
          className="report-form"
        >
          <label className="report-label">Embed URL:</label>
          <input
            className="report-input"
            type="text"
            name="embedurl"
            value={formData.embedurl}
            onChange={handleInputChange}
            required
          />

          <label className="report-label">
            {viewType === "reports" ? "Report Name:" : "Dashboard Name:"}
          </label>
          <input
            className="report-input"
            type="text"
            name="report_name"
            value={formData.report_name}
            onChange={handleInputChange}
            required
          />

          <label className="report-label">
            {viewType === "reports" ? "Report Number:" : "Dashboard Number:"}
          </label>
          <input
            className="report-input"
            type="text"
            name="report_number"
            value={formData.report_number}
            onChange={handleInputChange}
            required
          />

          <div className="report-buttons">
            <button className="report-add-btn" type="submit">{editingItem ? "Update" : "Add"}</button>
            <button className="modal-close-button" type="button" onClick={closeForm}>
              Cancel
            </button>{" "}
            {/* Cancel button */}
          </div>
        </form>
      </Modal>
      <PanModal
        isOpen={isPanModalOpen}
        onRequestClose={() => setIsPanModalOpen(false)}
        workspaceId={selectedWorkspaceId}
        existingName={existingName} // Pass the existing name
        existingIsActive={existingIsActive} // Pass the existing active status
        existingBoxFolderId={existingBoxFolderId}
        workspaceName={workspaceName}
        onUpdate={handlePanModalUpdate} // Pass the update function to PanModal
      />
      <CreateWorkspaceModal
        isOpen={isCreateWorkspaceModalOpen}
        onClose={handleCloseCreateWorkspaceModal}
        onSubmit={handleWorkspaceSubmit}
      />
      <GstinModel
        isOpen={isGstinModalOpen}
        onClose={() => setIsGstinModalOpen(false)}
        workspaceId={selectedWorkspaceId}
        workspaceName={workspaceName}
      />
    </div>
  );
};

export default ReportTable;
