import ReportManager from ".";
import ReportCreate from "./components/ReportCreate";
import ReportSchema from "./components/ReportSchema";
import ReportSelection from "./components/ReportSelection";
import ReportView from "./components/ReportView";

//eslint-disable-next-line
export default {
  path: "/reportmanager/",
  element: <ReportManager />,
  strict: true,
  children: [
    {
      path: "/reportmanager/view/:reportId",
      element: <ReportView />,
      exact: true,
    },
    {
      path: "/reportmanager/create",
      element: <ReportCreate />,
      exact: true,
    },
    {
      path: "/reportmanager/list",
      element: <ReportSelection />,
      exact: true,
    },
    {
      path: "/reportmanager/create/view/schema/:reportId",
      element: <ReportSchema />,
      exact: true,
    },
    {
      path: "/reportmanager/edit/view/schema/:reportId",
      element: <ReportSchema editMode />,
      exact: true,
    },
  ],
};
