import { Button, Checkbox, Divider, Form, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_AUTH_SIGNIN_OTP,
  API_ENDPOINT_GET_USER_INFO,
} from "../../auth.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";

type FieldType = {
  email?: string;
  password?: string;
};

export default function Login(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  const [basicAuth, setBasicAuth] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const navigate = useNavigate();

  useEffect(() => {
    const queryEmail = query.get("email") || ""; //
    const parsedEmail = queryEmail.replace(" ", "+");
    console.log("query email", parsedEmail);

    const queryPassword = query.get("password") || ""; //

    setEmail(parsedEmail);
    setPassword(queryPassword);
    if (parsedEmail && queryPassword)
      handleLoginNew(parsedEmail, queryPassword);
  }, []);

  const handleLoginNew = async (newEmail: string, newPassword: string) => {
    setLoading(true);
    let payload = {
      email: newEmail,
      password: newPassword,
    };
    const response = await apiPost(API_ENDPOINT_AUTH_SIGNIN, payload);
    if (response.status) {
      localStorage.setItem("token", response.data.token);
      fetchUserInfo();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
    if (response.status) {
      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) => item.name === "MMT Admin"
      );
      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
      });
      localStorage.setItem(
        "currentWorkspace",
        mmtWorkspace ? mmtWorkspace.id : response.data.workspaces[0]?.id
      );
      messageApi.success({
        type: "success",
        content: response.message,
      });
      if (props.noRouting) {
        props.onSuccess && props.onSuccess();
      } else {
        if (response.data.hasOnboared) {
          navigate("/dashboard");
        } else {
          navigate("/dashboard");
        }
      }
    }
  };
  const handleLogin = async () => {
    setLoading(true);
    let payload = {
      email: email,
      password: password,
    };
    const response = await apiPost(API_ENDPOINT_AUTH_SIGNIN, payload);
    if (response.status) {
      localStorage.setItem("token", response.data.token);
      fetchUserInfo();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleLoginWithOTP = async () => {
    setLoading(true);
    let payload = {
      email: email,
    };
    const response = await apiPost(API_ENDPOINT_AUTH_SIGNIN_OTP, payload);
    if (response.status) {
      if (props.noRouting) {
        props.onOTPSend && props.onOTPSend({ email: email });
      } else {
        navigate("/auth/signin_otp", { state: { email: email } });
      }
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleInitPasswordLogin = () => {
    setBasicAuth(true);
  };

  return (
    <div className="AuthForm">
      {props.noRouting ? null : (
        <div className="FormHeader">
          <Typography style={{ color: colorPicker("neutral.700") }}>
            Welcome
          </Typography>
          <Typography
            style={{
              fontSize: 40,
              color: colorPicker("neutral.950"),
              marginTop: -6,
            }}
          >
            Sign in
          </Typography>
        </div>
      )}
      <div className="FieldContainer">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          // onFinish={handleLogin}
          autoComplete="off"
          onFinish={basicAuth ? handleLogin : handleInitPasswordLogin}
        >
          <div className="FieldGroup">
            <Form.Item<FieldType>
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter your email!",
                },
              ]}
            >
              <TextField
                label="Email"
                placeholder="Email"
                labelColor="var(--text-color-gray)"
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
                defaultValue={email}
              />
            </Form.Item>
          </div>
          {basicAuth ? (
            <div className="FieldGroup" style={{ marginTop: 28 }}>
              <Form.Item<FieldType>
                name="password"
                // rules={[
                //   { required: true, message: "Please enter your password!" },
                // ]}
              >
                <TextField
                  label="Password"
                  placeholder="Password"
                  labelColor="var(--text-color-gray)"
                  onChange={(e: any) => setPassword(e.target.value)}
                  type="password"
                />
              </Form.Item>
            </div>
          ) : null}

          <div className="FieldGroup" style={{ marginTop: 38 }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              size="large"
              // disabled={!email || !password}
              loading={isLoading}
              htmlType={"submit"}
            >
              {basicAuth ? "Sign in" : "Sign in with password"}
            </Button>
          </div>

          {basicAuth ? null : (
            <>
              <Divider>
                <Typography
                  variant="xs"
                  style={{ color: colorPicker("neutral.800") }}
                >
                  OR
                </Typography>
              </Divider>

              <div className="FieldGroup" style={{ marginTop: 38 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                  ghost
                  // disabled={!email || !password}
                  loading={isLoading}
                  onClick={handleLoginWithOTP}
                >
                  Sign in with OTP
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
      {contextHolder}
    </div>
  );
}
