// CreateWorkspaceModal.js
import React, { useState } from "react";

const CreateWorkspaceModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    is_active: true,
    box_folder_id: "",
  });

  const handleSubmit = async () => {
    try {
      const response = await fetch("https://stage-apiplatform.finkraft.ai/api/meta/workspace/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create workspace");
      }

      // Reset form after submission
      setFormData({ name: "", is_active: true, box_folder_id: "" });
      console.log(formData);
      onSubmit(formData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    isOpen && (
      <div className="create-workspace-modal">
        <div>
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h3  style={{marginBottom:'20px' }}>Create New Workspace</h3>
          <div className="input-group">
            <label>Workspace Name:</label>
            <input
              type="text"
              className="workspacecreateinput"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label>Box Folder ID:</label>
            <input
             className="workspacecreateinput"
              type="text"
              value={formData.box_folder_id}
              onChange={(e) => setFormData({ ...formData, box_folder_id: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label>
              <input
                type="checkbox"
                checked={formData.is_active}
                onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
              />
              Is Active
            </label>
          </div>
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    )
  );
};

export default CreateWorkspaceModal;
