import PageHeader from "app/shared/PageHeader";
import "./SchemaColmapHistory.scss";
import Uploader from "app/shared/Uploader";

import { useEffect, useState } from "react";
import {
  uploadInfo,
  uploadProgressInfo,
  userInfo,
} from "app/config/States/users";
import GlobalUploader from "app/shared/GlobalUploader";
import { useRecoilState } from "recoil";
import AgTableClient from "app/shared/AgTable/AgTableClient";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_STORAGE_OBJECTS_LOGS } from "../../finkanalytics.constants";
import { Button, Input, Modal, Progress, Select, Tag } from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  CheckCircleFilled,
  DownloadOutlined,
  EyeOutlined,
  CloseCircleFilled,
  EyeFilled,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "app/shared/Loader";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import moment from "moment";
import { API_ENDPOINT_STORAGE_S3_SIGNED_URL } from "app/scenes/HotelBookingView/hotel.booking.constants";

export default function SchemaColmapHistory(props: any) {
  const [fileUploadInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);
  const [rowData, setRowData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [frequency, setFrequency] = useState("");
  const [dataset, setDataset] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [uploadProgresState, setUploadProgressInfo] =
    useRecoilState<any>(uploadProgressInfo);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (fileUploadInfo?.file) return;
    handleFetchRowData();
  }, [fileUploadInfo]);

  const handleFetchRowData = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_STORAGE_OBJECTS_LOGS);
    if (response.status) {
      setRowData(response.data);
    }
    setLoading(false);
  };

  const handleSelectedFile = (file: any) => {
    setUploadInfo({ file });
    setShowModal(false);
    setRowData([
      ...rowData,
      {
        filname: file.name,
        dataset_type: dataset,
        created_at: moment(),
        user_info: {
          email: userDetails?.email,
        },
      },
    ]);
  };

  const handleOpenCSVFile = async (file: string) => {
    const response = await apiGet(
      API_ENDPOINT_STORAGE_S3_SIGNED_URL + "?url=" + file
    );
    if (response.status) {
      window.open(response.data);
    } else {
    }
  };

  const columnDefs = [
    {
      field: "filename",
      headerName: "File Name",
      enableRowGroup: true,
      cellRenderer: (params: any) => {
        return (
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate("/FA/schema/colmap/preview/" + params.data?.file_id)
            }
          >
            <EyeFilled
              style={{
                marginRight: 4,
                fontSize: 22,
                color: colorPicker("primary.700"),
              }}
            />
            {params.data?.filename}
          </Typography>
        );
      },
    },
    {
      field: "dataset_type",
      headerName: "Dataset Type",
      formatType: "CHIP",
      enableRowGroup: true,
    },
    {
      field: "row_count",
      headerName: "Total Row Count",
      cellRenderer: (params: any) => {
        return (
          <Typography>
            {params.data?.row_count ? (
              <CheckCircleFilled
                style={{ color: colorPicker("green.700"), marginRight: 12 }}
              />
            ) : null}
            {params.data?.row_count}
          </Typography>
        );
      },
    },
    {
      field: "failed_row_count",
      headerName: "Total Failed Row ",
      cellRenderer: (params: any) => {
        return (
          <Button
            icon={<DownloadOutlined />}
            size="small"
            danger
            onClick={() => handleOpenCSVFile(params.data?.failed_csv)}
          >
            {params.data?.failed_row_count}
          </Button>
        );
      },
    },
    {
      headerName: "Email Sent",
      enableRowGroup: true,
      cellRenderer: (params: any) => {
        return (
          <Tag
            color={params.data?.meta?.mailsent ? "green" : "red"}
            icon={
              params.data?.meta?.mailsent ? (
                <CheckCircleFilled
                  style={{ color: colorPicker("green.700") }}
                />
              ) : (
                <CloseCircleFilled style={{ color: colorPicker("red.700") }} />
              )
            }
          >
            {params.data?.meta?.mailsent ? "YES" : "NO"}
          </Tag>
        );
      },
    },
    {
      headerName: "Email Sent Time",
      enableRowGroup: true,
      cellRenderer: (params: any) => {
        return (
          <Tag>
            {moment(params.data.meta?.mailsenttime).format(
              "DD-MM-YYYY HH:MM A"
            )}
          </Tag>
        );
      },
    },
    {
      headerName: "Email Recipients",
      enableRowGroup: true,
      cellRenderer: (params: any) => {
        return (
          <div>
            {(params.data?.meta?.mailrecipent || []).map(
              (item: any, key: any) => {
                return <Tag key={key}>{item}</Tag>;
              }
            )}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: any) => {
        return params.data?.status === "PENDING" ? (
          <Tag color="orange">{params.data?.status}</Tag>
        ) : params.data?.status === "PROCESSING" ? (
          <Tag color="orange" icon={<LoadingOutlined />}>
            PROCESSING
          </Tag>
        ) : params.data?.status === "DUPLICATE" ? (
          <Tag color="red" icon={<CloseCircleFilled />}>
            DUPLICATE
          </Tag>
        ) : (
          <Tag color="green" icon={<CheckCircleFilled />}>
            PROCESSSED
          </Tag>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      cellRenderer: (params: any) => {
        return (
          <>
            {!params.data?.dataset_type ? (
              <Button
                size="small"
                onClick={() => {
                  setShowModalFor("COLUMN");
                  setShowModal(true);
                  setSelectedFile(params?.data?.filename);
                }}
                style={{ marginRight: 24 }}
              >
                Create Mapping
              </Button>
            ) : null}

            <Tag
              icon={<EyeOutlined />}
              onClick={() => {
                navigate("/FA/schema/edit/" + params?.data?.id);
              }}
            >
              View
            </Tag>
          </>
        );
      },
    },
  ];

  return (
    <div className="SchemaColmapHistory">
      <div className="Header">
        <PageHeader
          title="File Upload History"
          goBack
          rightActions={
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowModalFor("UPLOAD");
                setShowModal(true);
              }}
            >
              Upload New File
            </Button>
          }
        />
      </div>

      <div className="ContentContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={rowData}
            columnDefs={
              fileUploadInfo?.file
                ? [
                    ...columnDefs,
                    {
                      field: "status",
                      headerName: "Status",
                      cellRenderer: (params: any) => {
                        const isLastRow =
                          params.node.rowIndex ===
                          params.api.getDisplayedRowCount() - 1;

                        return isLastRow ? (
                          <Progress
                            percent={uploadProgresState}
                            status="active"
                            showInfo={false}
                          />
                        ) : null;
                      },
                    },
                  ]
                : columnDefs
            }
            pdfDataKey="S3 Link"
            autoResize
            hideToolbar
            // autoGroupColumnDef={["Vendor Name"]}
          />
        )}
      </div>
      <Modal
        open={showModal}
        title="Upload File"
        footer
        onCancel={() => setShowModal(false)}
      >
        {showModalFor === "UPLOAD" ? (
          <Uploader onSelect={handleSelectedFile} />
        ) : (
          <div style={{ marginTop: 24 }}>
            <Select
              style={{ width: "100%", marginBottom: 12 }}
              size="small"
              placeholder="Select Dataset"
              options={[
                {
                  label: "Booking",
                  value: "BOOKING",
                },
                {
                  label: "Invoice",
                  value: "INVOICE",
                },
                {
                  label: "2A/2B",
                  value: "2A_2B",
                },
              ]}
              onChange={(selectedValue: any) => setDataset(selectedValue)}
            />

            <Button
              onClick={() =>
                navigate("/FA/schema/columnmap", {
                  state: {
                    filename: selectedFile,
                    frequency: frequency,
                    dataset: dataset,
                  },
                })
              }
              style={{ width: "100%" }}
              type="primary"
            >
              Next
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}
