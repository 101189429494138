import PageHeader from "app/shared/PageHeader";
import "../../../FinkAnalytics/FinkAnalytics.scss";
import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Tag,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { AgTableClient } from "app/shared/AgTable";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_FETCH_COLUMN_MAPPING_FOR_DESTINATION,
  API_ENDPOINT_FETCH_DB_TABLE_PREVIEW,
  API_ENDPOINT_FETCH_DB_TABLE_PREVIEW_PG,
  API_ENDPOINT_FETCH_S3_FILE_DATA,
  API_ENDPOINT_ZA_TABLE_CREATE_SCHEMA,
  API_ENDPOINT_ZA_TABLE_CREATE_SCHEMAV2,
  API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS,
  API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF,
  API_ENDPOINT_ZA_TABLE_UPDATE_SCHEMA,
  API_ENDPOINT_ZA_TABLE_UPDATE_SCHEMAV2,
} from "../../../FinkAnalytics/finkanalytics.constants";
import Loader from "app/shared/Loader";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { includes } from "lodash";
import { processZARowForColumnDefs } from "app/scenes/FinkAnalytics/fa.helper";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

const dataTypeColor: any = {
  STRING: "#2980b9",
  NUMBER: "#27ae60",
  DATE: "#34495e",
};

const UI_FORMAT_TYPES: any = [
  { value: "FALSE", label: "None" },
  { value: "CHIP", label: "Chip" },
  { value: "CHIP_SHORT_TEXT", label: "Chip with Sort Text" },
  { value: "RATE", label: "Rate %" },
  { value: "ENTITY", label: "Entity" },
  { value: "AIRLINE", label: "Airline" },
  { value: "INVOICE", label: "INVOICE" },
  { value: "PERSON", label: "Person" },
  { value: "NUMBER", label: "Number" },

  { value: "AMOUNT", label: "Amount" },
  { value: "AMOUNT_BOLD", label: "Amount In Bold" },
  { value: "GSTIN_STATE", label: "GSTIN State" },
  { value: "DATE", label: "Date" },
  { value: "TAGS", label: "Tags" },
  { value: "ROUTE", label: "Route " },
  { value: "MATCH", label: "Match" },
  { value: "MATCH_COMPARE", label: "Match Compare" },
  { value: "MATCH_PRESENCE", label: "Check Key Existence " },
  { value: "MATCH_COMPARE_TEXT", label: "Compare Text" },
];

const VALIDATION_TYPES: any = [
  { value: "GSTIN", label: "GSTIN" },
  { value: "EMAIL", label: "Email" },
  { value: "PNR", label: "PNR" },
  { value: "PAN", label: "PAN" },
];

export default function ReportSchema(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<any[]>([]);
  const { schemaId, reportId } = useParams(); // Extract the id from the URL
  const [configData, setConfigData] = useState("");
  const [selectedCol, setSelectedCol] = useState<any>(null);
  const [schemaInfo, setSchemaInfo] = useState<any>(null);
  const gridRef: any = useRef();
  const [schemaName, setSchemaName] = useState(
    "schema_" + Math.floor(1000 + Math.random() * 9000)
  );
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const [columnMapList, setColumnMapList] = useState([]);
  const [moduleId, setModuleId] = useState("CRM");
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const { dbType, database, table, sourceType, zaId } = location.state || {};
  const [searchParams] = useSearchParams();
  const onlyColumnMapping = location?.pathname.includes("columnmap");
  const S3_OBJECT_FILE_NAME = location.state?.filename;
  const { dataset, frequency, selectedWorkspaceId } = location?.state || {};
  const navigate = useNavigate();
  useEffect(() => {
    fetchMappingColumns();
    if (schemaId) {
      fetchEditDetails();
    } else if (onlyColumnMapping) {
      fetchPreviewDataForS3Object();
    } else {
      fetchGeneratableDataForSchmea();
    }
  }, []);

  const fetchMappingColumns = async () => {
    const response = await apiPost(
      API_ENDPOINT_FETCH_COLUMN_MAPPING_FOR_DESTINATION
    );
    if (response.status) {
      let processColumns: any = [];
      (response.data || []).forEach((element: any) => {
        processColumns.push({
          label: element.column_name,
          value: element.column_name,
          dataType: element.data_type,
          is_nullable: element.is_nullable,
        });
      });
      setColumnMapList(processColumns);
    }
  };

  const fetchGeneratableDataForSchmea = () => {
    if (onlyColumnMapping) return;
    if (sourceType === "zohoanalytics") {
      fetchZATableData();
    } else {
      fetchInternalDBDataForSchema();
    }
  };

  const fetchPreviewDataForS3Object = async () => {
    setLoading(true);

    const response = await apiPost(API_ENDPOINT_FETCH_S3_FILE_DATA, {
      filename: S3_OBJECT_FILE_NAME,
    });

    setTableData(response.data);
    const processed = processZARowForColumnDefs(response.data, false, true);
    setRowData(processed);
    setLoading(false);
  };

  const fetchInternalDBDataForSchema = async () => {
    setLoading(true);
    const payload = {
      startRow: 0,
      endRow: 100,
      rowGroupCols: [],
      valueCols: [],
      pivotCols: [],
      pivotMode: false,
      groupKeys: [],
      filterModel: {},
      sortModel: [],
      database: database,
      dbType: dbType,
      table: table,
    };
    const response = await apiPost(
      dbType === "postgre"
        ? API_ENDPOINT_FETCH_DB_TABLE_PREVIEW_PG
        : API_ENDPOINT_FETCH_DB_TABLE_PREVIEW,
      payload
    );

    setTableData(response.data.rows);
    const processed = processZARowForColumnDefs(response.data.rows, false);
    setRowData(processed);
    setLoading(false);
  };

  const fetchEditDetails = async () => {
    setLoading(true);
    const response = await apiGet(
      `${API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS}/${schemaId}`
    );
    setSchemaInfo(response.data);
    setSchemaName(response.data.name);
    setModuleId(response.data.moduleId);

    processMongoDefination(response.data?.state);
  };

  const agFilterTypeMap: any = {
    agTextColumnFilter: "STRING",
    agNumberColumnFilter: "NUMBER",
    agDateColumnFilter: "DATE",
  };
  const processMongoDefination = (CoulmnConfigData: any) => {
    let mainColDefs: any = [];
    let columnArray = CoulmnConfigData.columnDefs;
    columnArray.forEach((element: any, mainIndex: any) => {
      if (element.children && element.children.length > 0) {
        element.children.forEach((childElement: any, index: any) => {
          let getSourceKey = (CoulmnConfigData.columnMapping || []).find(
            (item: any) => item.destination_key === childElement.field
          );
          mainColDefs.push({
            id: `column_${mainIndex}_${index}`,
            source_key: getSourceKey?.source_key || childElement.field,
            destination_key: childElement.field,
            data_type: agFilterTypeMap[childElement.filter] || "STRING",
            header_name: childElement.headerName,
            ui_format_type: childElement.formatType || "NONE",
            enable_aggregation: childElement.enableValue,
            enable_row_group: childElement.enableRowGroup,
            enable_column_pivot: childElement.enablePivot,
            filter_type: childElement.filter,
            hide_column: childElement.hide,
            default_grouping: childElement.rowGroup,
            default_pinning: childElement.pinned || "NONE",
            column_group_show: childElement.columnGroupShow || "NONE",
            default_sorting: childElement.sort || "NONE",
            column_grouping: element.headerName,
            match_config_json: JSON.stringify(childElement.formatProps || {}),
            // match_config_json: "",
          });
        });
      } else {
        let findSourceKey = (CoulmnConfigData.columnMapping || []).find(
          (item: any) => item.destination_key === element.field
        );
        mainColDefs.push({
          id: "column_main_" + mainIndex,
          source_key: findSourceKey?.source_key || element.field,
          destination_key: element.field,
          data_type: agFilterTypeMap[element.filter] || "STRING",
          header_name: element.headerName,
          ui_format_type: element.formatType || "NONE",
          enable_aggregation: element.enableValue,
          enable_row_group: element.enableRowGroup,
          enable_column_pivot: element.enablePivot,
          filter_type: element.filter,
          hide_column: element.hide,
          default_grouping: element.rowGroup,
          default_pinning: element.pinned || "NONE",
          column_group_show: element.columnGroupShow || "NONE",
          default_sorting: element.sort || "NONE",
          match_config_json: JSON.stringify(element.formatProps || {}),
        });
      }
    });
    setRowData(mainColDefs);
    setLoading(false);
  };

  const dataTypeMap: any = {
    "character varying": "STRING",
    date: "DATE",
    numeric: "NUMBER",
  };

  const fetchZATableData = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF, {
      tableId: zaId,
      tableName: zaId,
    });
    setTableData(response);
    const processed = processZARowForColumnDefs(response, true);
    setRowData(processed);
    setLoading(false);
  };

  const handleUpdateSchemaMeta = (
    columnData: any,
    changeInfo: any,
    columnId: any,
    dataTypeCheck = false,
    fullObjectValue: any = null
  ) => {
    console.log("data and value", fullObjectValue, columnData);

    if (dataTypeCheck) {
      if (columnData.data_type !== dataTypeMap[fullObjectValue.dataType]) {
        messageApi.open({
          type: "error",
          content: "Data Type does not match use appropriate value",
        });
        return;
      }
    }
    // Create a copy of the row to be updated
    if (columnData[columnId] === changeInfo) {
      return; // If no change, exit early
    }
    const updatedRow = {
      ...columnData,
      [columnId]: changeInfo,
    };
    console.log("updated row is:", updatedRow);

    // Apply the update transaction
    gridRef.current &&
      gridRef.current.applyTransaction({ update: [updatedRow] });
  };

  const handleDeleteRow = (rowId: string) => {
    gridRef.current &&
      gridRef.current.applyTransaction({ remove: [{ id: rowId }] });
  };

  const processForDuplicateRows = (arrayData: any) => {
    const seen = new Set();
    return arrayData.filter((item: any) => {
      const keyValue = item["source_key"];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  };

  const columnDefs = [
    {
      field: "source_key",
      headerName: "Source Key",
      width: 300,
      suppressMenu: true,
      rowDrag: true,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Source Key"
          size="small"
          id={params?.data?.source_key}
          defaultValue={params?.data?.source_key}
          style={{
            fontSize: 11,

            width: 250,
          }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "source_key")
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "source_key")
          }
        />
      ),
    },
    {
      field: "destination_key",
      headerName: "Destination Key",
      width: 240,
      suppressMenu: true,
      cellRenderer: (params: any) =>
        onlyColumnMapping ? (
          <Select
            defaultValue={params?.data?.destination_key}
            style={{ width: "100%" }}
            size="small"
            onChange={(selectedValue: any) => {
              handleUpdateSchemaMeta(
                params.data,
                selectedValue,
                "destination_key"
              );
            }}
            options={columnMapList}
          />
        ) : (
          <Input
            placeholder="Destination Key"
            size="small"
            id={params?.data?.destination_key}
            defaultValue={params?.data?.destination_key}
            style={{ fontSize: 11 }}
            onPressEnter={(e: any) =>
              handleUpdateSchemaMeta(
                params.data,
                e.target.value,
                "destination_key"
              )
            }
            onBlur={(e: any) =>
              handleUpdateSchemaMeta(
                params.data,
                e.target.value,
                "destination_key"
              )
            }
          />
        ),
    },
    {
      field: "data_type",
      headerName: "Data Type",
      suppressMenu: true,
      width: 120,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.data_type}
          style={{ width: "100%" }}
          size="small"
          onChange={(selectedValue: any) => {
            handleUpdateSchemaMeta(params.data, selectedValue, "data_type");
          }}
          options={[
            { value: "NUMBER", label: "Number" },
            { value: "STRING", label: "Text" },
            { value: "DATE", label: "Date" },
          ]}
        />
      ),
    },
    {
      field: "header_name",
      headerName: "Header Name",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Header Name"
          size="small"
          id={params?.data?.header_name}
          defaultValue={params?.data?.header_name}
          style={{ fontSize: 11 }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "header_name")
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "header_name")
          }
        />
      ),
    },
    {
      field: "ui_format_type",
      headerName: "UI Format Type",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.ui_format_type}
          style={{ width: "100%" }}
          size="small"
          options={UI_FORMAT_TYPES}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(params.data, selectedValue, "ui_format_type")
          }
        />
      ),
    },
    {
      field: "match_config_json",
      headerName: "UI Format Config",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Input
          defaultValue={params?.data?.match_config_json}
          onBlur={(e) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "match_config_json"
            )
          }
        />
      ),
    },
    {
      field: "column_grouping",
      headerName: "Column Groping",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Column Group Name"
          size="small"
          id={params.data?.column_grouping}
          value={params.data?.column_grouping}
          defaultValue={params?.data?.column_grouping}
          style={{ fontSize: 11 }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "column_grouping"
            )
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "column_grouping"
            )
          }
        />
      ),
    },
    {
      field: "enable_row_group",
      headerName: "Row Groping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.enable_row_group}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.enable_row_group,
              "enable_row_group"
            )
          }
        />
      ),
    },

    {
      field: "enable_aggregation",
      headerName: "Aggregation",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.enable_aggregation}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.enable_aggregation,
              "enable_aggregation"
            )
          }
        />
      ),
    },
    {
      field: "enable_column_pivot",
      headerName: "Column Pivot",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.enable_column_pivot}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.enable_column_pivot,
              "enable_column_pivot"
            )
          }
        />
      ),
    },
    {
      field: "hide_column",
      headerName: "Hide Column",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.hide_column}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.hide_column,
              "hide_column"
            )
          }
        />
      ),
    },
    {
      field: "default_grouping",
      headerName: "Default Grouping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.default_grouping}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.default_grouping,
              "default_grouping"
            )
          }
        />
      ),
    },
    {
      field: "default_sorting",
      headerName: "Default Sorting",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.default_sorting}
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "FALSE", label: "None" },
            { value: "ASC", label: "Ascending" },
            { value: "DESC", label: "Descending" },
          ]}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "default_sorting"
            )
          }
        />
      ),
    },
    {
      field: "default_pinning",
      headerName: "Default Pinning",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.default_pinning}
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "FALSE", label: "None" },
            { value: "LEFT", label: "Left" },
            { value: "RIGHT", label: "Right" },
          ]}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "default_pinning"
            )
          }
        />
      ),
    },
    {
      field: "column_group_show",
      headerName: "Column Group Show",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.column_group_show}
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "FALSE", label: "None" },
            { value: "open", label: "Open" },
            { value: "close", label: "Close" },
            { value: `["open","close"]`, label: "Both" },
          ]}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "column_group_show"
            )
          }
        />
      ),
    },

    {
      field: "action",
      headerName: "Action",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Button
          size="small"
          danger
          onClick={() => handleDeleteRow(params?.data?.id)}
        >
          DELETE
        </Button>
      ),
    },
  ];

  const ColumnMappingDefs = [
    {
      field: "source_key",
      headerName: "Source Key",
      width: 300,
      suppressMenu: true,
      rowDrag: true,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Source Key"
          size="small"
          id={params?.data?.source_key}
          defaultValue={params?.data?.source_key}
          style={{
            fontSize: 11,

            width: 250,
          }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "source_key")
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "source_key")
          }
        />
      ),
    },
    {
      field: "destination_key",
      headerName: "Destination Key",
      width: 240,
      suppressMenu: true,
      cellRenderer: (params: any) =>
        onlyColumnMapping ? (
          <Select
            defaultValue={params?.data?.destination_key}
            style={{ width: "100%" }}
            size="small"
            onChange={(selectedValue: any, fullObjectValue: any) => {
              console.log("fullObjectValue:", fullObjectValue);
              handleUpdateSchemaMeta(
                params.data,
                selectedValue,
                "destination_key",
                true,
                fullObjectValue
              );
            }}
            options={columnMapList}
          />
        ) : (
          <Input
            placeholder="Destination Key"
            size="small"
            id={params?.data?.destination_key}
            defaultValue={params?.data?.destination_key}
            style={{ fontSize: 11 }}
            onPressEnter={(e: any) =>
              handleUpdateSchemaMeta(
                params.data,
                e.target.value,
                "destination_key"
              )
            }
            onBlur={(e: any) =>
              handleUpdateSchemaMeta(
                params.data,
                e.target.value,
                "destination_key"
              )
            }
          />
        ),
    },
    {
      field: "data_type",
      headerName: "Data Type",
      suppressMenu: true,
      width: 120,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.data_type}
          style={{ width: "100%" }}
          size="small"
          onChange={(selectedValue: any) => {
            handleUpdateSchemaMeta(params.data, selectedValue, "data_type");
          }}
          options={[
            { value: "NUMBER", label: "Number" },
            { value: "STRING", label: "Text" },
            { value: "DATE", label: "Date" },
          ]}
        />
      ),
    },
    {
      field: "validation_type",
      headerName: "Validation Type",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.validation_type}
          style={{ width: "100%" }}
          size="small"
          options={VALIDATION_TYPES}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "validation_type"
            )
          }
        />
      ),
    },
    {
      field: "is_required",
      headerName: "Is Required",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.is_required}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.is_required,
              "is_required"
            )
          }
        />
      ),
    },
  ];

  const getRequiredCriteriaMatched = (payloadData: any) => {
    let requiredFields = columnMapList.filter(
      (item: any) => item.is_nullable === "NO"
    );

    let finalColumnRequired = (requiredFields || []).map(
      (item: any) => item.value
    );

    let result: any = [];

    finalColumnRequired.forEach((field: any) => {
      const item = payloadData.find(
        (entry: any) => entry.destination_key === field
      );
      if (!item) {
        result.push(field);
      }
    });

    return result;

    // let totalRequiredFieldsUsed = payloadData.filter((item:any)=>item.);
  };
  const handleSaveSchema = async () => {
    const payloadData =
      gridRef.current && gridRef.current.getFinalDataFromTable();

    if (onlyColumnMapping) {
      const extractedData = getRequiredCriteriaMatched(payloadData);
      if (extractedData.length > 0) {
        messageApi.open({
          type: "error",
          content: `Required Fields need to be mapped ${JSON.stringify(
            extractedData
          )}`,
        });
        return;
      }
    }
    // handleDownload();
    const payload = {
      state: payloadData,
      name: schemaName,
      moduleId: moduleId,
      schemaId: schemaInfo?.id,
      column_mapping: sourceType === "zohoanalytics" ? true : false,
      dbType: dbType,
      database: database,
      table: table,
      frequency: frequency,
      dataset: dataset,
      reportId: reportId,
      workspace_id: selectedWorkspaceId,
    };

    const response = await apiPost(
      API_ENDPOINT_ZA_TABLE_CREATE_SCHEMAV2,
      payload
    );
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Awesome! your schema has been saved",
      });
      navigate("/reportmanager/view/" + reportId, {
        state: {
          dbType: dbType,
          database: database,
          table: table,
          report_name: "",
          selectedWorkspaceId: selectedWorkspaceId,
        },
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Could not save schema",
      });
    }
  };
  const handleUpdateSchema = async () => {
    const payloadData =
      gridRef.current && gridRef.current.getFinalDataFromTable();
    // handleDownload();
    const payload = {
      state: payloadData,
      name: schemaName,
      moduleId: moduleId,
      schemaId: schemaId,
      reportId: reportId,
      workspace_id: selectedWorkspaceId,
    };
    console.log("ag paylload:", payload);

    const response = await apiPost(
      API_ENDPOINT_ZA_TABLE_UPDATE_SCHEMAV2,
      payload
    );
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Awesome! your schema has been saved",
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Could not save schema",
      });
    }
  };

  const handleAddNewRow = () => {
    let newRowObj = {
      id: `column_${rowData.length + 1}`,
      source_key: "source_key",
      destination_key: "destination_key",
      data_type: "STRING",
      header_name: "New Header",
      ui_format_type: "FALSE",
      enable_aggregation: false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter_type: "agTextColumnFilter",
      hide_column: false,
      default_grouping: false,
      default_pinning: "FALSE",
      column_group_show: false,
      default_sorting: "FALSE",
      column_grouping: "",
      match_config_json: "{}",
    };
    // setRowData([newRowObj, ...rowData]);
    gridRef.current && gridRef.current.applyTransaction({ add: [newRowObj] });
  };
  return (
    <div className="FASetup" style={{ height: "100%" }}>
      <div className="Header">
        <PageHeader
          leftActions={
            <>
              <Input
                size="small"
                placeholder="Schema Name"
                style={{ width: 220, marginRight: 12 }}
                value={schemaName}
                onChange={(e: any) => setSchemaName(e.target.value)}
              />
            </>
          }
          goBack
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Popconfirm
                placement="topLeft"
                title={"Saving Schema"}
                description={"Are you sure want to save this"}
                okText="Yes"
                cancelText="No"
                onConfirm={
                  props.editMode ? handleUpdateSchema : handleSaveSchema
                }
              >
                <Button
                  size="small"
                  type="primary"
                  icon={<SaveOutlined />}
                  // onClick={handleSaveSchema}
                >
                  Save Schema
                </Button>
              </Popconfirm>

              <Button
                size="small"
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginLeft: 24 }}
                onClick={handleAddNewRow}
              >
                Add New Row
              </Button>
            </div>
          }
        />
      </div>
      {/* <div className="SearchBoxContainer">
        <SearchTable
          placeholder="Search ZA Table"
          style={{ width: 400, height: 40 }}
          onChange={handleSearch}

        />
      </div> */}
      <div style={{ height: "100%", width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={processForDuplicateRows(rowData)}
            columnDefs={onlyColumnMapping ? ColumnMappingDefs : columnDefs}
            hideToolbar
            updateNewData={(data: any) => setRowData(data)}
            enableDragging
            dragColumn="source_key"
            showStatusBar
            ref={gridRef}
            disableLazy
            rowIdKey={"id"}
            useRowId
          />
        )}
      </div>

      <Modal
        title="UI Format Configuration"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <div>
          <Input
            defaultValue={configData}
            onBlur={(e) =>
              handleUpdateSchemaMeta(
                selectedCol,
                e.target.value,
                "match_config_json"
              )
            }
          />
          <Button
            size="small"
            style={{ marginTop: 24 }}
            type="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
