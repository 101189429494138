import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './AddItemPage.css'; // Importing the CSS file for styling
import './WorkspacePage.css'; // Importing the CSS file for styling
import gstimg from '../assets/gst05.jpg'

const AddItemPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [responseStatus, setResponseStatus] = useState(null);
  const [showWorkspacePage, setShowWorkspacePage] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [createNewWorkspace, setCreateNewWorkspace] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const gstin = location.state?.inputValue || inputValue; // Get the passed input value from the previous page or from inputValue
  const trimmedGstin = gstin.slice(2, -3); // Remove the first 2 and last 3 characters

  useEffect(() => {
    // Fetch workspaces from the API
    axios.get('https://apiplatform.finkraft.ai/meta/workspaces')
      .then(response => {
        setWorkspaces(response.data);
        setFilteredWorkspaces(response.data);
      })
      .catch(error => {
        console.error('Error fetching workspaces:', error);
      });
  }, []);

  useEffect(() => {
    // Filter workspaces based on the search query
    const filtered = workspaces.filter(workspace =>
      workspace.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredWorkspaces(filtered);
  }, [searchQuery, workspaces]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddClick = async () => {
    try {
      const response = await axios.post('https://stage-apiplatform.finkraft.ai/api/meta/gstin/add', {
        gstin: inputValue,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200 && response.data.data.mapworkspace === true) {
        setResponseStatus(true);
        setShowWorkspacePage(true); 
      }
      else if(response.status === 200 && response.data.status === true) {
        setResponseStatus(true)
        setInputValue(" ")
        alert("GSTIN Added Successfully")
      }
      else {
        setResponseStatus(false);
      }
    } catch (error) {
      console.error('Error adding item:', error);
      setResponseStatus(false);
    }
  };

  const handleCheckboxChange = () => {
    setCreateNewWorkspace(!createNewWorkspace);
    setSelectedWorkspace(null); // Deselect any selected workspace
  };

  const handleWorkspaceSelection = (workspaceId) => {
    setSelectedWorkspace(workspaceId);
    setCreateNewWorkspace(false); // Uncheck the checkbox if a workspace is selected
  };

  const handleSave = () => {
    if (createNewWorkspace) {
      // Send request with "create new workspace" message
      axios.post(`https://stage-apiplatform.finkraft.ai/api/meta/pan/mapworkspace/${trimmedGstin}`, {
        create_new_workspace: true
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        console.log('Response:', response.data);
        alert('Response sent successfully!');
        // Navigate to another page or show a success message
      })
      .catch(error => {
        alert('Failed to save. Please try again.');
        console.error('Error creating workspace:', error);
      });
    } else if (selectedWorkspace) {
      // Send selected workspace details
      const selected = workspaces.find(ws => ws.id === selectedWorkspace);
      axios.post(`https://stage-apiplatform.finkraft.ai/api/meta/pan/mapworkspace/${trimmedGstin}`, {
        workspace_id: selected.id,
        create_new_workspace: false
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        console.log('Response:', response.data);
        alert('Workspace Mapped successfully!');
        // Navigate to another page or show a success message
      })
      .catch(error => {
        console.error('Error saving workspace:', error);
        alert('Failed to save. Please try again.');
      });
    } else {
      alert('Please select a workspace or choose to create a new one.');
    }
  };

  return (
    <div>
      {!showWorkspacePage ? (
        <div className="add-item-page">
          <div>
          <h1 className="header-gst">ADD GSTIN</h1>
          <div className="form-container-gstin">
            <input 
              type="text" 
              value={inputValue} 
              onChange={handleInputChange} 
              placeholder="Enter GSTIN" 
              className="input-box-gstin"
            />
            <button onClick={handleAddClick} className="add-button">ADD</button>
          </div>
          {responseStatus === false && <p className="error-message">Failed to add item.</p>}
          </div>
          <div>
            <img src={gstimg} className='gstimg-addgstpage'/>
          </div>  
        </div>
      ) : (
        <div className="workspace-container">
          <h1 className="header-gst-2">Select or Create Workspace</h1>
          <div className="workspace-options">
            <div className="workspace-left">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={createNewWorkspace}
                  onChange={handleCheckboxChange}
                  className="checkbox-input"
                />
                Create New Workspace
              </label>
            </div>
            <div className="workspace-right">
              <input
                type="text"
                placeholder="Search workspaces..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input-gst"
              />
              <ul className="workspace-list">
                {filteredWorkspaces.map(workspace => (
                  <li key={workspace.id} className="workspace-item">
                    <label>
                      <input
                        type="radio"
                        name="workspace"
                        value={workspace.id}
                        checked={selectedWorkspace === workspace.id}
                        onChange={() => handleWorkspaceSelection(workspace.id)}
                        disabled={createNewWorkspace} // Disable when the checkbox is selected
                        className="radio-input"
                      />
                      {workspace.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="save-button-container">
              <button onClick={handleSave} className="save-button">Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddItemPage;
