import React, { useEffect, useState } from 'react';
import './App.css'; // Assuming styles are in this file

const WorkspaceModal = ({ userId, clusterId, onClose, isOpen,username,useremail }) => {
  const [clusterData, setClusterData] = useState([]); // Holds grouped clusters and workspaces
  const [filteredClusters, setFilteredClusters] = useState([]); // Filtered list for clusters
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]); // Filtered list for workspaces
  const [selectedCluster, setSelectedCluster] = useState(''); // Stores selected cluster
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]); // Checkboxes state
  const [existingWorkspaces, setExistingWorkspaces] = useState([]); // Workspaces from DB (already saved)
  const [workspaceList, setWorkspaceList] = useState([]); // Store full list of workspaces
  const [selectAllChecked, setSelectAllChecked] = useState(false); // State for "Select All" checkbox

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user-specific workspace data
        const userResponse = await fetch(`https://stage-apiplatform.finkraft.ai/api/meta/user-workspaces/${userId}`);
        const userData = await userResponse.json();
        console.log("User Data:", userData); // Debugging line

        // Fetch all workspaces from the workspace API
        const workspaceResponse = await fetch('https://apiplatform.finkraft.ai/meta/workspaces');
        const workspaceListData = await workspaceResponse.json();
        console.log("Workspace List Data:", workspaceListData); // Debugging line

        // Store the full workspace list for later use
        setWorkspaceList(workspaceListData);

        // Extract user workspace names by mapping workspace IDs to names
        const userWorkspaces = userData.data
          .map(item => {
            const workspace = workspaceListData.find(ws => ws.id === item.workspace_id);
            return workspace ? workspace.name : null;
          })
          .filter(Boolean); // Filter out null values

        setExistingWorkspaces(userWorkspaces); // Set user workspaces as checked by default (existing)
        setSelectedWorkspaces(userWorkspaces); // Initially checked

        // Filter active workspaces
        const activeWorkspaces = workspaceListData
          .filter(workspace => workspace.is_active)
          .map(workspace => workspace.name);

        // Combine user workspaces (existing) at the top with active workspaces (excluding duplicates)
        const combinedWorkspaces = [
          ...userWorkspaces, // Existing workspaces first
          ...activeWorkspaces.filter(ws => !userWorkspaces.includes(ws)) // Rest of the active workspaces
        ];

        setFilteredWorkspaces(combinedWorkspaces); // Set combined workspaces

        // Fetch cluster list from API
        const clusterResponse = await fetch('https://stage-apiplatform.finkraft.ai/api/meta/cluster/list');
        const clusterListData = await clusterResponse.json();
        console.log("Cluster List Data:", clusterListData); // Debugging line

        if (clusterListData.status) {
          const clusters = clusterListData.data
            .filter(cluster => cluster.name) // Ensure name exists
            .map(cluster => ({
              id: cluster.id, // Map cluster ID
              name: cluster.name,
              workspaces: cluster.workspaces || [],
            }));

          setFilteredClusters(clusters); // Set filtered clusters directly
        }

      } catch (error) {
        console.error('Error fetching workspace/cluster data:', error);
      }
    };

    if (isOpen && userId) {
      fetchData();
      setSelectedCluster(clusterId); // Set the selected cluster based on passed clusterId
    }
  }, [isOpen, userId, clusterId]);

  // Handle workspace checkbox toggle
  const handleWorkspaceToggle = (workspaceName) => {
    setSelectedWorkspaces((prevSelected) =>
      prevSelected.includes(workspaceName)
        ? prevSelected.filter((name) => name !== workspaceName)
        : [...prevSelected, workspaceName]
    );
  };

  // Handle "Select All" checkbox
  const handleSelectAllToggle = () => {
    if (selectAllChecked) {
      // If "Select All" is already checked, unselect all new workspaces
      setSelectedWorkspaces(existingWorkspaces);
    } else {
      // Select all new workspaces (not in existingWorkspaces)
      const newWorkspaces = filteredWorkspaces.filter(ws => !existingWorkspaces.includes(ws));
      setSelectedWorkspaces([...existingWorkspaces, ...newWorkspaces]);
    }
    setSelectAllChecked(!selectAllChecked); // Toggle the "Select All" checkbox state
  };

  // Handle cluster selection (radio button)
  const handleClusterSelect = (clusterId) => {
    setSelectedCluster(clusterId);
  };

  // Handle cluster search
  const handleClusterSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setFilteredClusters(
      clusterData.filter((cluster) =>
        cluster.name.toLowerCase().includes(searchTerm)
      )
    );
  };

  // Handle workspace search
  const handleWorkspaceSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setFilteredWorkspaces(
      workspaceList
        .filter(workspace => workspace.is_active) // Filter active workspaces
        .map(workspace => workspace.name)
        .filter((workspace) => workspace.toLowerCase().includes(searchTerm))
    );
    setSelectAllChecked(false); // Uncheck "Select All" if user starts searching
  };

  // Handle Save button: Send only newly selected workspaces
  const handleSave = () => {
    const newWorkspaces = selectedWorkspaces.filter(ws => !existingWorkspaces.includes(ws));
    
    if (newWorkspaces.length === 0) {
      alert("Please select at least one new workspace.");
      return;
    }

    // Get the corresponding workspace IDs for the selected workspace names
    const workspaceIds = newWorkspaces
      .map(name => workspaceList.find(ws => ws.name === name)?.id)
      .filter(Boolean); // Filter out any undefined values

    const payload = {
      userId,
      clusterId: selectedCluster,
      workspaces: workspaceIds, // Send only new workspace IDs
    };

    // Send payload to backend (you can update this URL to match your backend API)
    fetch('https://stage-apiplatform.finkraft.ai/api/meta/map-user-workspace', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Save successful:', data);
        onClose(); // Close modal on successful save
      })
      .catch(error => {
        console.error('Error saving workspaces:', error);
      });
  };

  if (!isOpen) return null; // Do not render the modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="user-modal-content">
        <h2  style={{marginBottom:'20px' }}>Workspace and Cluster Details for User : {useremail}</h2>
        <div className="modal-footer">
          <button className='modal-submit-button' onClick={handleSave}>Save</button>
          <button  className='modal-close-button' onClick={onClose}>Cancel</button>
        </div>

        <div className="modal-body">
          {/* Left side: Cluster list with search */}
          <div className="modal-left">
            <h3>Clusters</h3>
            <input
              type="text"
              placeholder="Search Clusters..."
              className='modal-input-box'
              onChange={handleClusterSearch}
            />
            <ul className="cluster-list">
              {filteredClusters.length > 0 ? (
                filteredClusters.map((cluster) => (
                  <li key={cluster.id}>
                    <label>
                      <input
                        type="radio"
                        name="cluster"
                        value={cluster.id}
                        checked={selectedCluster === cluster.id} // Check if this cluster is selected
                        onChange={() => handleClusterSelect(cluster.id)}
                      />
                      {cluster.name} {/* Render cluster name only */}
                    </label>
                  </li>
                ))
              ) : (
                <li>No clusters found.</li> // Fallback in case of empty clusters
              )}
            </ul>
          </div>

          {/* Right side: Workspace list with search */}
          <div className="modal-right">
            <h3>Workspaces</h3>
            <input
              type="text"
              placeholder="Search Workspaces..."
              onChange={handleWorkspaceSearch}
              className='modal-input-box'
            />
            <label>
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAllToggle}
              />
              Select All New Workspaces
            </label>
            <ul className="workspace-list">
              {filteredWorkspaces.length > 0 ? (
                filteredWorkspaces.map((workspace, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedWorkspaces.includes(workspace)}
                        onChange={() => handleWorkspaceToggle(workspace)}
                      />
                      {workspace} {/* Render workspace name only */}
                    </label>
                  </li>
                ))
              ) : (
                <li>No workspaces found.</li> // Fallback in case of empty workspaces
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceModal;
