import PageHeader from "app/shared/PageHeader";
import "./ReportSelection.scss";
import { useEffect, useState } from "react";
import Loader from "app/shared/Loader";
import { AgTableClient } from "app/shared/AgTable";
import moment from "moment";
import { Button, Input, message, Modal, Popconfirm, Select, Tag } from "antd";
import {
  EyeOutlined,
  GlobalOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { apiDelete, apiPost, apiPut } from "app/services/apiServices";
import {
  API_ENDPOINT_REPORT_CREATE_DUPLICATE,
  API_ENDPOINT_REPORT_DELETE,
  API_ENDPOINT_REPORT_MANAGER_LIST,
  API_ENDPOINT_REPORT_PUBLISH_NOW,
} from "../../report.manager.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
export default function ReportSelection(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [reportList, setReportList] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [creating, setCreating] = useState(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [linkResponse, setLinkResponse] = useState<any>(null);
  const [reportName, setReportName] = useState("");

  useEffect(() => {
    if (!isLoading) fetchReportList();
  }, []);

  const fetchReportList = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_REPORT_MANAGER_LIST, {});
    if (response.status) {
      setReportList(response.data);
    } else {
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const handlePublishReport = async (
    reportId = selectedReport,
    status = "PUBLISH",
    workspaceId: string
  ) => {
    setCreating(true);
    const response = await apiPut(
      `${API_ENDPOINT_REPORT_PUBLISH_NOW}${reportId}`,
      {
        status: status,
        workspace_id: workspaceId,
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Your report has been published",
      });
      setLinkResponse(response.data);
      fetchReportList();
    } else {
    }
    setCreating(false);
  };

  const handleDeleteReport = async (reportId: string) => {
    const response = await apiDelete(API_ENDPOINT_REPORT_DELETE + reportId);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Your report has been deleted",
      });
      fetchReportList();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not delete your report",
      });
    }
  };

  const handleCreateDuplicateReport = async (reportId: string) => {
    const response = await apiPost(
      API_ENDPOINT_REPORT_CREATE_DUPLICATE + reportId,
      {
        name: reportName,
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Your report has been duplicated",
      });
      fetchReportList();
      setShowModal(false);
    } else {
      messageApi.error({
        type: "error",
        content: "Could not duplicate  report",
      });
    }
  };
  const columnDefs = [
    {
      field: "report_name",
      headerName: "Report Name",
      width: 200,
    },
    {
      field: "workspace_name",
      headerName: "Workspace",
      width: 200,
    },

    {
      field: "userInfo.name",
      headerName: "Created By",
      width: 200,
    },
    {
      field: "created_on",
      headerName: "Created On",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,

      cellRenderer: (params: any) => {
        return params.data?.status === "DRAFT" ? (
          params.data?.status
        ) : (
          <Tag color="green" icon={<GlobalOutlined />}>
            {params.data?.status}
          </Tag>
        );
      },
    },
    {
      field: "report_link",
      headerName: "Publish Link",
      width: 300,
      cellRenderer: (params: any) => {
        return params.data?.["report_link"] ? (
          <Tag
            color="green"
            icon={<CopyOutlined />}
            onClick={() => copyDataToBoard(params.data?.["report_link"])}
          >
            Click to copy
          </Tag>
        ) : null;
      },
    },
    {
      field: "action",
      headerName: "Action",

      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="small"
              icon={<EyeOutlined />}
              onClick={() =>
                navigate("/reportmanager/view/" + params.data?._id, {
                  state: {
                    dbType: params.data?.meta?.dbType,
                    database: params.data?.meta?.database,
                    table: params.data?.meta?.table,
                    report_name: params.data?.report_name,
                    selectedWorkspaceId: params.data?.workspace_id,
                  },
                })
              }
            >
              View
            </Button>
            {params.data?.status === "DRAFT" ? (
              <Popconfirm
                title="Publish Report"
                description="Are you sure want to publish this report?"
                onConfirm={() =>
                  handlePublishReport(
                    params.data._id,
                    "PUBLISH",
                    params.data?.workspace_id
                  )
                }
              >
                <Button
                  size="small"
                  icon={<GlobalOutlined />}
                  style={{ marginLeft: 12 }}
                  type="primary"
                >
                  Publish
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="UnPublish Report"
                description="Are you sure want to unpublish this report?"
                onConfirm={() =>
                  handlePublishReport(
                    params.data._id,
                    "DRAFT",
                    params.data?.workspace_id
                  )
                }
              >
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  style={{ marginLeft: 12 }}
                  danger
                >
                  Redraft
                </Button>
              </Popconfirm>
            )}

            <Popconfirm
              title="Delete Report"
              description="Are you sure want to delete this report?"
              onConfirm={() => handleDeleteReport(params.data._id)}
            >
              <Button
                icon={<DeleteOutlined />}
                style={{ marginLeft: 12 }}
                size="small"
                danger
              >
                Delete
              </Button>
            </Popconfirm>

            <Button
              size="small"
              icon={<CopyOutlined />}
              style={{ marginLeft: 12 }}
              onClick={() => {
                setShowModal(true);
                setSelectedReport(params.data);
              }}
            >
              Duplicate
            </Button>

            <Button
              size="small"
              icon={<NodeIndexOutlined />}
              style={{ marginLeft: 12 }}
              onClick={() => {
                navigate(
                  "/reportmanager/edit/view/schema/" + params.data?._id,
                  {
                    state: {
                      dbType: params.data?.meta?.dbType,
                      database: params.data?.meta?.database,
                      table: params.data?.meta?.table,
                      report_name: params.data?.report_name,
                      selectedWorkspaceId: params.data?.workspace_id,
                    },
                  }
                );
              }}
            >
              Schema View
            </Button>
          </div>
        );
      },
    },
  ];

  const handleSelectWorkspace = (spaceInfo: any, fullObj: any) => {
    setSelectedWorkspace(spaceInfo);
  };

  const { Option } = Select;
  const copyDataToBoard = (data: any) => {
    navigator?.clipboard?.writeText(data);
    messageApi.open({
      type: "success",
      content: "copied",
    });
  };
  return (
    <div className="ReportSelection">
      <div className="HeaderContainer">
        <PageHeader
          title="Report List"
          rightActions={
            <div>
              <Button
                icon={<PlusOutlined />}
                size="small"
                type="primary"
                onClick={() => navigate("/reportmanager/create")}
              >
                Create New Report
              </Button>
            </div>
          }
        />
      </div>
      <div className="MainContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={reportList}
            columnDefs={columnDefs}
            hideToolbar
          />
        )}
      </div>
      {contextHolder}
      <Modal
        title={`Dupliate - ${selectedReport?.report_name}`}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
      >
        <div style={{ marginTop: 24 }}>
          <Input
            placeholder="Report Name"
            onChange={(e: any) => setReportName(e.target.value)}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginTop: 24 }}
              size="small"
              type="primary"
              loading={creating}
              //@ts-ignore
              onClick={() => handleCreateDuplicateReport(selectedReport?._id)}
            >
              Create Duplicate
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
