import PageHeader from "app/shared/PageHeader";
import React, { useState } from "react";
import "./SettingHome.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { useNavigate } from "react-router-dom";
import {
  NodeIndexOutlined,
  TableOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
export default function SettingHome(props: any) {
  const navigate = useNavigate();
  const handleSelectSource = (source: any) => {
    navigate("/FA/schema/source/detail/" + source);
  };
  return (
    <div className="SettingHome">
      <div className="Header">
        <PageHeader title="Settings" />
      </div>

      <div className="MainContent">
        <div className="SourceContainer">
          <div
            className="SourceCard"
            onClick={() => navigate("/fa/schema/list")}
          >
            <NodeIndexOutlined style={{ fontSize: 34, marginBottom: 8 }} />
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginTop: 6 }}
              id={"cardTitle"}
            >
              Schema Maker
            </Typography>
            <Typography
              style={{ marginTop: 2, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.Lorem Ipsum i
            </Typography>
          </div>
          <div
            className="SourceCard"
            onClick={() => navigate("/fa/schema/upload/history")}
          >
            <TableOutlined style={{ fontSize: 34, marginBottom: 8 }} />
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginTop: 6 }}
            >
              Column Mapping
            </Typography>
            <Typography
              style={{ marginTop: 2, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.Lorem Ipsum i
            </Typography>
          </div>
          <div
            className="SourceCard"
            onClick={() => navigate("/reportmanager/list")}
          >
            <FileSearchOutlined style={{ fontSize: 34, marginBottom: 8 }} />
            <Typography
              weight={500}
              style={{ color: colorPicker("neutral.800"), marginTop: 6 }}
            >
              Report Manager
            </Typography>
            <Typography
              style={{ marginTop: 2, color: colorPicker("neutral.700") }}
              variant="xs"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.Lorem Ipsum i
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
